import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faFileAlt,
  faArrowRight,
  faGear,
  faList,
  faFileExcel,
  faListCheck,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(true);
  const [isHawbMenuOpen, setIsHawbMenuOpen] = useState(true);
  const [isOrderMenuOpen, setIsOrderMenuOpen] = useState(true);
  const [isStockMenuOpen, setIsStockMenuOpen] = useState(true);
  const [isManageMenuOpen, setIsManageMenuOpen] = useState(true);
  const [isPayMenuOpen, setIsPayMenuOpen] = useState(true);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(true);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(true);
  const [activePath, setActivePath] = useState("/");

  const toggleAdminMenu = () => setIsAdminMenuOpen(!isAdminMenuOpen);
  const toggleHawbMenu = () => setIsHawbMenuOpen(!isHawbMenuOpen);
  const toggleOrderMenu = () => setIsOrderMenuOpen(!isOrderMenuOpen);
  const toggleStockMenu = () => setIsStockMenuOpen(!isStockMenuOpen);
  const toggleManageMenu = () => setIsManageMenuOpen(!isManageMenuOpen);
  const togglePayMenu = () => setIsPayMenuOpen(!isPayMenuOpen);
  const toggleProductMenu = () => setIsProductMenuOpen(!isProductMenuOpen);
  const toggleSettingMenu = () => setIsSettingMenuOpen(!isSettingMenuOpen);

  const adminMenuItems = [
    {
      id: "admin",
      title: "관리자",
      icon: faUserTie,
      toggleMenu: toggleAdminMenu,
      isOpen: isAdminMenuOpen,
      subItems: [
        { title: "주문등록완료", link: "/admin/registered" },
        { title: "주문 처리 중", link: "/admin/processing" },
        { title: "발송완료", link: "/admin/done" },
        { title: "주문취소", link: "/admin/cancel" },
        { title: "픽업주문", link: "/admin/order/pickup" },
        { title: "회계", link: "/admin/ledger" },
        { title: "할인제품", link: "/admin/specialprice" },
        { title: "도매업체", link: "/admin/company" },
      ],
    },
  ];

  const userMenuItems = [
    {
      id: "pay",
      title: "페이",
      icon: faMoneyCheckDollar,
      toggleMenu: togglePayMenu,
      isOpen: isPayMenuOpen,
      subItems: [
        { title: "선불충전금", link: "/pay/deposit" },
        { title: "포인트", link: "/pay/point" },
        { title: "인보이스", link: "/pay/invoice" },
      ],
    },
    {
      id: "order",
      title: "주문",
      icon: faFileExcel,
      toggleMenu: toggleOrderMenu,
      isOpen: isOrderMenuOpen,
      subItems: [
        { title: "엑셀주문", link: "/upload" },
        { title: "엑셀주문목록", link: "/list" },
        { title: "픽업주문", link: "/pickup" },
        { title: "픽업주문목록", link: "/pickuplist" },
      ],
    },
    {
      id: "order_manage",
      title: "엑셀 주문관리",
      icon: faListCheck,
      toggleMenu: toggleManageMenu,
      isOpen: isManageMenuOpen,
      subItems: [
        { title: "주문등록 완료", link: "/manage/registered" },
        { title: "주문 처리 중", link: "/manage/processing" },
        { title: "발송완료", link: "/manage/done" },
        { title: "주문취소", link: "/manage/cancel" },
      ],
    },
    {
      id: "product",
      title: "제품",
      icon: faListCheck,
      toggleMenu: toggleProductMenu,
      isOpen: isProductMenuOpen,
      subItems: [{ title: "제품검색", link: "/product" }],
    },
    // {
    //   id: "setting",
    //   title: "설정",
    //   icon: faGear,
    //   toggleMenu: toggleSettingMenu,
    //   isOpen: isSettingMenuOpen,
    //   subItems: [{ title: "항공배송 일정", link: "/setting/airplan" }],
    // },
  ];

  const renderMenuItems = (items) => {
    return items.map((item) => (
      <ListGroup className="border mt-2" key={item.title}>
        <ListGroupItem action onClick={item.toggleMenu} className="no-border">
          <FontAwesomeIcon
            icon={item.icon}
            style={{ marginRight: "10px", color: "gray" }}
          />
          <span className="fontsize_1rem bold_600">{item.title}</span>
        </ListGroupItem>
        <Collapse isOpen={item.isOpen}>
          {item.subItems.map((item, idx) => (
            <ListGroup key={idx}>
              <ListGroupItem action className="no-border fontsize_09rem">
                <NavLink
                  to={item.link}
                  className={({ isActive }) =>
                    isActive ? "text-dark" : "no-deco text-dark"
                  }
                  onClick={() => setActivePath(item.link)}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  {item.title}
                </NavLink>
              </ListGroupItem>
            </ListGroup>
          ))}
        </Collapse>
      </ListGroup>
    ));
  };

  return (
    <div
      className="no-border"
      style={{ height: "100%", minWidth: "170px", maxWidth: "170px" }}
    >
      <br />

      {/* 관리자 메뉴 */}
      {user &&
        user.groups &&
        user.groups.includes("admin") &&
        renderMenuItems(adminMenuItems)}

      {/* 사용자 메뉴 */}

      {renderMenuItems(userMenuItems)}

      <div className="p-5"></div>
    </div>
  );
};

export default Sidebar;
