import React, { useState, useEffect, useRef, useContext } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import readXlsxFile from "read-excel-file";
import axios from "axios";
import { Table, Button, Row, Col } from "reactstrap";

import Moment from "react-moment";
import sample from "../../sample/special_price_sample.xlsx";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminSpecialPrice() {
  const [data, setData] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getSpecialPriceData();
  }, []);

  const getSpecialPriceData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/product/special`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.msg === "no auth") {
        navigate("/", { replace: true });
      }
      setData(resList.data.data.list);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const readUploadFile = async (e) => {
    // 1단계 : 엑셀데이터 rows에 담기
    const rows = await readXlsxFile(e.target.files[0]).then((resRows) => {
      return resRows;
    });

    fileInputRef.current.value = "";

    let products = [];
    let pcodes = [];

    rows.map((row, idx) => {
      try {
        // 엑셀파일 2번째 행부터 각각의 필요한 필드 저장하기
        if (idx !== 0) {
          let code = row[0];
          let name = row[1];
          let price = row[2];
          let note = row[3];
          products.push({ code, name, price, note });
          pcodes.push(code);
        }
      } catch (err) {
        console.log(err);
      }
    });

    // 할인제품 등록하기
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/product/special`,
        {
          products,
          pcodes,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (result.data.result === "success") {
        await getSpecialPriceData();
        alert("업데이트 완료되었습니다.");
      } else {
        alert("업데이트 파일 확인 부탁드립니다.");
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <NormalLayout>
      <div>
        <h3>
          할인제품 {data && data.length !== 0 ? <>({data.length}건)</> : <></>}
        </h3>
      </div>
      <div className="p-3">
        <Row>
          <Col>
            {" "}
            <li>
              할인제품에 등록된 제품은 도매업체 주문시 우선반영되는 가격입니다.
            </li>
            <li>
              할인제품 업데이트는{" "}
              <a href={sample} target="_blank" rel="noreferrer">
                샘플파일 양식
              </a>
              을 참고해서 업로드해주세요.
            </li>
          </Col>
          <Col>
            <form className="p-5 border">
              <label htmlFor="upload">엑셀파일 업로드</label>
              <input
                type="file"
                name="upload"
                id="upload"
                ref={fileInputRef}
                onChange={readUploadFile}
              />
            </form>
          </Col>
        </Row>
      </div>
      {data ? (
        <>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">No</th>
                  <th className="text-center">상품코드</th>
                  <th className="text-center p-2 border">상품명</th>
                  <th className="text-center p-2 border">가격</th>
                  <th className="text-center p-2 border">비고</th>
                  <th className="text-center p-2 border">등록일</th>
                </tr>
              </thead>
              <tbody>
                {data.map((p, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center">{p.product_code}</td>
                      <td className="text-start">{p.en_name}</td>
                      <td className="text-center">{p.kor}</td>
                      <td className="text-center">{p.note}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {p.created_at}
                        </Moment>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>등록된 Special Price 제품 없음</>
      )}
    </NormalLayout>
  );
}

export default AdminSpecialPrice;
