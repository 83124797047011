import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Container, Row, Col } from "reactstrap";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

export default function NoSidebarLayout({ children }) {
  const { user, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isLoading) {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }

  return (
    <div>
      <Header />
      <Container fluid>{children}</Container>
    </div>
  );
}
