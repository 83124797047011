import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

const CompanyForm = ({
  fetchCompanies,
  selectedCompany,
  setSelectedCompany,
  setShow,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [wholesaler, setWholesaler] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerTel, setManagerTel] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [grade, setGrade] = useState("");
  const [creditPurchaseEnabled, setCreditPurchaseEnabled] = useState(false);
  const [gstIncluded, setGstIncluded] = useState(false);
  const [memo, setMemo] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    console.log(selectedCompany);

    if (selectedCompany) {
      setCompanyName(selectedCompany.company_name);
      setWholesaler(selectedCompany.wholesaler);
      setManagerName(
        selectedCompany.manager_name !== null
          ? selectedCompany.manager_name
          : ""
      );
      setManagerTel(
        selectedCompany.manager_tel !== null ? selectedCompany.manager_tel : ""
      );
      setManagerEmail(selectedCompany.manager_email);
      setGrade(selectedCompany.purchase_grade);
      setMemo(
        selectedCompany.company_memo !== null
          ? selectedCompany.company_memo
          : ""
      );
      setCreditPurchaseEnabled(selectedCompany.credit_purchase_enabled);
      setGstIncluded(selectedCompany.gst);
      setAddress(
        selectedCompany.company_address !== null
          ? selectedCompany.company_address
          : ""
      );
    } else {
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setCreditPurchaseEnabled(false);
      setGstIncluded(false);
      setMemo("");
      setAddress("");
    }
  }, [selectedCompany]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCompany) {
        await axios.put(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company/${selectedCompany.company_index}`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            creditPurchaseEnabled,
            gstIncluded,
            memo,
            address,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            creditPurchaseEnabled,
            gstIncluded,
            memo,
            address,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      }
      fetchCompanies();
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setCreditPurchaseEnabled(false);
      setGstIncluded(false);
      setMemo("");
      setAddress("");
      setSelectedCompany(null);
      setShow(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyName">회사 이름(사업자등록증 기준)</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="wholesaler">업체명(주문서 작성용)</Label>
                  <Input
                    type="text"
                    name="wholesaler"
                    id="wholesaler"
                    value={wholesaler}
                    onChange={(e) => setWholesaler(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerName">담당자명</Label>
                  <Input
                    type="text"
                    name="managerName"
                    id="managerName"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerTel">전화번호</Label>
                  <Input
                    type="text"
                    name="managerTel"
                    id="managerTel"
                    value={managerTel}
                    onChange={(e) => setManagerTel(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerEmail">이메일</Label>
                  <Input
                    type="text"
                    name="managerEmail"
                    id="managerEmail"
                    value={managerEmail}
                    onChange={(e) => setManagerEmail(e.target.value)}
                    required
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="grade">그룹</Label>
                  <Input
                    type="select"
                    name="grade"
                    id="grade"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    required
                  >
                    <option value="">선택...</option>
                    <option value="korg">KORG</option>
                    <option value="kor">KOR</option>
                    <option value="locaa">LOCAA</option>
                    <option value="loca">LOCA</option>
                    <option value="ventus">VENTUS</option>
                    <option value="vip">VIP</option>
                    <option value="online">ONLINE</option>
                  </Input>
                </FormGroup>{" "}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="deliveryFee">신용구매</Label>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="creditEnabled"
                            checked={creditPurchaseEnabled === true}
                            onChange={(e) => setCreditPurchaseEnabled(true)}
                          />{" "}
                          가능
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      {" "}
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="creditEnabled"
                            checked={creditPurchaseEnabled === false}
                            onChange={(e) => setCreditPurchaseEnabled(false)}
                          />{" "}
                          불가능
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="gst">GST</Label>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="gst"
                            checked={gstIncluded === true}
                            onChange={(e) => setGstIncluded(true)}
                          />{" "}
                          환급
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      {" "}
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="gst"
                            checked={gstIncluded === false}
                            onChange={(e) => setGstIncluded(false)}
                          />{" "}
                          영세율{" "}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="address">주소</Label>
                  <Input
                    type="address"
                    name="address"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="memo">메모</Label>
                  <Input
                    type="memo"
                    name="memo"
                    id="memo"
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label for="deliveryFee">배송료 기준</Label>
                  <Row>
                    <Col>
                      <li>
                        뉴질랜드 :{" "}
                        {deliveryFee["newzealand"] === null
                          ? "설정필요"
                          : `$${deliveryFee["newzealand"]}`}
                      </li>
                      <li>
                        호주 :{" "}
                        {deliveryFee["australia"] === null
                          ? "설정필요"
                          : `$${deliveryFee["australia"]}`}
                      </li>
                      <li>
                        하이웰 :{" "}
                        {deliveryFee["hiwell"] === null
                          ? "설정필요"
                          : `$${deliveryFee["hiwell"]}`}
                      </li>
                      <li>
                        비타존 :{" "}
                        {deliveryFee["vitazone"] === null
                          ? "설정필요"
                          : `$${deliveryFee["vitazone"]}`}
                      </li>
                    </Col>
                    <Col>
                      <Button color="primary" block>
                        배송료 설정
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col> */}
            </Row>
            <Button type="submit" color="primary">
              도매업체 {selectedCompany ? "정보 수정" : "추가"}
            </Button>{" "}
            <Button color="secondary" onClick={() => setShow(false)}>
              취소
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyForm;
