import React, { useState, useRef, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import { genExcelDownloadFunc } from "../../utils/GenExcelDownloadFile";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Moment from "react-moment";
import { Cookies } from "react-cookie";
import AdminSearchForm from "../../components/search/AdminSearchForm";
import { excelUpdate } from "../../utils/AdminExcelUpdate";
import readXlsxFile from "read-excel-file";
import { useNavigate } from "react-router-dom";
import OrderForm from "../../components/admin/order/OrderForm";

const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminProcessing() {
  const [data, setData] = useState(null);
  const [companyObj, setCompanyObj] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [ordersObj, setOrdersObj] = useState({});
  const [wholesalerArr, setWholesalerArr] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState();
  const [currentWholesaler, setCurrentWholesaler] = useState();
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModifyForm, setShowModifyForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    excelListData();
  }, []);

  // 모달창 제어
  const confirmToggle = () => setConfirmModal(!confirmModal);
  const showModifyFormToggle = () => setShowModifyForm(!showModifyForm);

  const excelListData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order?status=PROCESSING`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
        genSummary(resList.data.data.list);
      }

      // 도매업체 정보 가져오기
      const companies = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      let tempCompanyObj = {};
      companies.data &&
        companies.data.length > 0 &&
        companies.data.map((c) => {
          tempCompanyObj[c.wholesaler] = {
            manager: c.manager_name,
            email: c.manager_email,
            deliveryFee: c.delivery_fee,
          };
        });

      setCompanyObj(tempCompanyObj);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 업체별 주문건 요약
  const genSummary = (orders) => {
    let tempObj = {};

    orders.map((order) => {
      if (tempObj.hasOwnProperty(order.wholesaler)) {
        tempObj[order.wholesaler].push(order);
      } else {
        tempObj[order.wholesaler] = [order];
      }
    });

    const sortedKeys = Object.keys(tempObj).sort();

    // 도매업체 리스트 및 업체별 주문건 설정
    setWholesalerArr(sortedKeys);
    setOrdersObj(tempObj);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });
    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      resValue =
        "$" +
        parseFloat(unitPrice * qauntity)
          .toFixed(2)
          .toString();
    }
    return resValue;
  };

  const selectedOrders = (wholesaler) => {
    setFilteredOrders(ordersObj[wholesaler]);
    setCurrentWholesaler(wholesaler);
  };

  const getExcelDownload = () => {
    let tempOrders = data;
    tempOrders.sort((a, b) => {
      if (a.wholesaler < b.wholesaler) {
        return -1;
      }
      if (a.wholesaler > b.wholesaler) {
        return 1;
      }
      return 0;
    });

    // 주문건 상태변경 : 주문등록  완료 > 주문처리중
    genExcelDownloadFunc(tempOrders);
  };

  const deleteOrder = async (orderIndex) => {
    try {
      const resList = await axios.delete(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order?orderindex=${orderIndex}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        excelListData();
      } else {
        console.error("주문건을 삭제하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건을 삭제하는데 실패했습니다", error);
    }
  };

  const handleDelete = (orderIndex, name) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${name}님 주문을 삭제하시겠습니까?`);

    if (isConfirmed) {
      deleteOrder(orderIndex);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  const toggleTooltip = (receiver) => {
    setTooltipOpen({
      ...tooltipOpen,
      [receiver]: !tooltipOpen[receiver],
    });
  };

  // 주문정보 업데이트를 위한 엑셀파일 분석
  const readUploadFile = async (e) => {
    // 1단계 : 엑셀데이터 rows에 담기
    const rows = await readXlsxFile(e.target.files[0]).then((resRows) => {
      return resRows;
    });

    fileInputRef.current.value = "";

    let result = excelUpdate(rows);

    if (result.ordersArr.length === 0) {
      alert("발송완료 변경대상 주문건이 없습니다.");
    } else {
      const targetWholesalerArr = Object.keys(result.wholesaleObj);
      result["targetWholesaler"] = targetWholesalerArr;

      setTargetData(result);
      confirmToggle();
    }
  };

  // 발송완료 & 발송취소 처리
  const handleSave = async () => {
    // 발송완료 변경 대상건 처리

    let wholesaleInfo = {};
    targetData.targetWholesaler.map((w) => {
      wholesaleInfo[w] = companyObj[w];
    });

    console.log("데이터 ", targetData);
    console.log("도매업체 ", wholesaleInfo);

    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order/update`,
        {
          ordersArr: targetData.ordersArr,
          ordersObj: targetData.ordersObj,
          wholesaleArr: targetData.targetWholesaler,
          wholesaleObj: targetData.wholesaleObj,
          wholesaleInfo: wholesaleInfo,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        // 완료 안내 및 페이지 이동
        confirmToggle();

        navigate("/admin/done");
      } else {
        console.error("주문건 상태를 업데이트하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건 상태를 업데이트하는데 실패했습니다", error);
    }
  };

  const handleModify = async (o) => {
    console.log(o);
    setSelectedOrder(o);
    setShowModifyForm(true);
  };

  return (
    <NormalLayout>
      <Modal isOpen={confirmModal} toggle={confirmToggle}>
        <ModalHeader toggle={confirmToggle}>
          주문 업데이트 확인{" "}
          {targetData && <>(총 {targetData.ordersArr.length}건)</>}
        </ModalHeader>
        <ModalBody>
          {targetData && (
            <>
              <div>
                {targetData.targetWholesaler.map((w, idx) => {
                  return (
                    <h5 key={idx}>
                      {w}:{targetData.wholesaleObj[w].length}건
                      {targetData.wholesaleObj[w].map((o, idx2) => {
                        return (
                          <li key={idx2}>
                            {targetData.ordersObj[o].customerName},
                            {targetData.ordersObj[o].hawb},
                            {targetData.ordersObj[o].weight}kg
                          </li>
                        );
                      })}
                    </h5>
                  );
                })}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleSave()}>
            저장
          </Button>
          <Button color="secondary" onClick={confirmToggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showModifyForm}
        toggle={showModifyFormToggle}
        className="custom-modal"
      >
        <ModalHeader toggle={showModifyFormToggle}>주문 수정하기</ModalHeader>
        <ModalBody>
          <OrderForm order={selectedOrder} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => alert("수정처리")}>
            저장
          </Button>
          <Button color="secondary" onClick={showModifyFormToggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>

      <div>
        <h3>
          주문 처리 중
          {data && data.length !== 0 ? <>({data.length}건)</> : <></>}
        </h3>
      </div>

      <div className="p-3"></div>
      {filteredOrders ? (
        <>
          <div className="border p-3">
            <div className="mb-2">
              <div className="p-3 border  bg-light">
                <h4>업체별 주문현황</h4>
                <Row className="mt-3">
                  {wholesalerArr &&
                    wholesalerArr.length > 0 &&
                    wholesalerArr.map((wholesaler, index) => {
                      return (
                        <Col
                          key={index}
                          md={2}
                          className="border p-2 rounded m-2 text-center bg-white"
                          style={{
                            backgroundColor:
                              currentWholesaler === wholesaler ? "orange" : "",
                          }}
                        >
                          <div onClick={() => selectedOrders(wholesaler)}>
                            <h6 className="bold_600">{wholesaler}</h6>
                            {ordersObj && ordersObj[wholesaler].length}건
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="p-1">
                <Row className="mt-1 p-2">
                  <Col md={"6"} className="border p-3">
                    <div className="">
                      <h4 className="bold-600">주문정보 다운로드</h4>
                      <div className="mt-3 text-center p-3">
                        {data && data.length > 0 && (
                          <Button
                            size="lg"
                            color="warning"
                            onClick={() => getExcelDownload()}
                          >
                            엑셀 다운로드
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={"6"} className="border p-3">
                    <div className="">
                      <h4 className="bold-600">주문정보 업데이트</h4>
                      <div className="mt-4 text-center">
                        <form className="p-3">
                          <label htmlFor="upload">엑셀파일 업로드</label>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            ref={fileInputRef}
                            onChange={readUploadFile}
                          />
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* <div className="p-3  mb-3 border"></div> */}
              <AdminSearchForm
                target="PROCESSING"
                setFilteredOrders={setFilteredOrders}
                token={cookies.get("info").token}
              />
            </div>
            <Table hover striped bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">등록일</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">요청메모</th>
                  <th className="text-center p-2 border">이름</th>
                  <th className="text-center p-2 border">통관부호</th>
                  <th className="text-center p-2 border">배송메모</th>
                  <th className="text-center p-2 border">주문상품</th>
                  <th className="text-center p-2 border">총액</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD">{o.created_at}</Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center cell">{o.shipper_memo}</td>
                      <td className="text-center">
                        <span id={`Tooltip-${o.order_index}`}>
                          {" "}
                          {o.customer_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[o.order_index]}
                          target={`Tooltip-${o.order_index}`}
                          toggle={() => toggleTooltip(o.order_index)}
                        >
                          <div className="text-start">
                            <li>T : {o.customer_tel}</li>
                            <li>M : {o.customer_mobile}</li>
                            <li>
                              A : {o.address}, #{o.zip}
                            </li>
                          </div>
                        </Tooltip>
                      </td>
                      {/* <td className="text-center">{o.customer_tel}</td>
                      <td className="text-center">{o.customer_mobile}</td> */}
                      <td className="text-center">{o.pcc}</td>

                      {/* <td className="text-center">{o.zip}</td>
                      <td>{o.address}</td> */}

                      <td>{o.delivery_memo}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      {/* <td className="text-center">{o.weight}</td>
                      <td className="text-center">{o.delivery_fee}</td> */}
                      <td className="text-center">
                        ${genAmount(o.order_product)}
                      </td>

                      <td className="text-center" style={{ width: "80px" }}>
                        <Button
                          size="sm"
                          color="primary"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleModify(o)}
                        >
                          수정
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </NormalLayout>
  );
}

export default AdminProcessing;
