import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// 폰트 등록
Font.register({
  family: "SpoqaHanSans",
  src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});

const styles = StyleSheet.create({
  viewer: {
    flex: 1,
  },
  korean: {
    fontFamily: "SpoqaHanSans",
  },
  english: {},
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  title: {
    fontSize: 40,
  },
  subTitle: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "SpoqaHanSans",
    fontSize: 16,
    weight: 500,
  },
  companyInfo: {
    fontSize: 10,
    textAlign: "right",
  },
  billTo: {
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
  },
  value: {
    fontSize: 10,
  },
  table: {
    flexDirection: "column",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomColor: "#bbb",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    backgroundColor: "#f0f0f0",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "#bbb",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  tableCol: {
    textAlign: "left",
    paddingLeft: 5,
  },
  tableCell: {
    fontSize: 10,
  },
  tableCellSm: {
    fontSize: 8,
  },
  total: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalLabel: {
    fontSize: 12,
    width: 100,
    textAlign: "right",
    paddingRight: 10,
  },
  totalValue: {
    fontSize: 12,
    width: 100,
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 10,
    textAlign: "center",
  },
});

const InvoiceForm = ({ invoice }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>TAX INVOICE</Text>
        <View style={styles.companyInfo}>
          <Text>Health Inside Limited</Text>
          <Text>Unit 7, 7 Henry Rose Place</Text>
          <Text>Albany</Text>
          <Text>Auckland</Text>
          <Text>New Zealand</Text>
          <Text>0632</Text>
        </View>
      </View>

      <View style={styles.billTo}>
        <Text style={styles.label}>Bill to:</Text>
        <Text style={styles.value}>{invoice.billTo.name}</Text>
        {/* <Text style={styles.value}>{invoice.billTo.address}</Text>
        <Text style={styles.value}>{invoice.billTo.city}</Text> */}
      </View>

      <View style={styles.billTo}>
        {/* <Text style={styles.label}>Invoice#: {invoice.invoiceNumber}</Text> */}
        <Text style={styles.label}>Date: {invoice.date}</Text>
      </View>

      {/* 요약정보 */}
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text
            style={[
              styles.tableCol,
              styles.tableCell,
              { width: "50%", textAlign: "center" },
            ]}
          >
            DESCRIPTION
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            PRICE
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            AMOUNT NZD
          </Text>
        </View>
        {/* 제품가격 */}
        <View style={styles.tableRow}>
          <Text
            style={[
              styles.tableCol,
              styles.tableCell,
              { width: "50%", textAlign: "center" },
            ]}
          >
            Product {invoice.gst && "(GST Included)"}
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.amountProductPrice}
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.amountProductPrice}
            {invoice.gst && `(GST : ${invoice.summary.gst})`}
          </Text>
        </View>
        {/* 배송비 */}
        <View style={styles.tableRow}>
          <Text
            style={[
              styles.tableCol,
              styles.tableCell,
              { width: "50%", textAlign: "center" },
            ]}
          >
            Shipping
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.amountDeliveryFee}
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.amountDeliveryFee}
          </Text>
        </View>

        {/* 포인트 결제 내역이 있는 경우 출력 */}
        {invoice.summary.amountPoint !== 0 && (
          <View style={styles.tableRow}>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                styles.korean,
                { width: "50%", textAlign: "center" },
              ]}
            >
              Discount(포인트 결제)
            </Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "center" },
              ]}
            >
              -${invoice.summary.amountPoint.toFixed(2)}
            </Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "center" },
              ]}
            >
              -${invoice.summary.amountPoint.toFixed(2)}{" "}
              {invoice.gst &&
                `(GST : -$${(
                  invoice.summary.amountPoint -
                  invoice.summary.amountPoint / 1.15
                ).toFixed(2)})`}
            </Text>
          </View>
        )}

        {/* 총계 */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text
            style={[
              styles.tableCol,
              styles.tableCell,
              { width: "50%", textAlign: "center" },
            ]}
          >
            Total NZD
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          ></Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.totalAmount}
          </Text>
        </View>

        {/* 선불충전금으로 결제 내역이 있는 경우 출력 */}
        {invoice.summary.amountDeposit !== 0 && (
          <View style={styles.tableRow}>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                styles.korean,
                { width: "50%", textAlign: "center" },
              ]}
            >
              Less Amount Credited(선불충전금 결제)
            </Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "center" },
              ]}
            ></Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "center" },
              ]}
            >
              -${invoice.summary.amountDeposit.toFixed(2)}
            </Text>
          </View>
        )}

        {/* 최종 정산할 금액 */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text
            style={[
              styles.tableCol,
              styles.tableCell,
              { width: "50%", textAlign: "center" },
            ]}
          >
            Amount Due NZD
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          ></Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "center" }]}
          >
            ${invoice.summary.amountCredit.toFixed(2)}
          </Text>
        </View>
      </View>

      <View style={styles.subTitle}>
        <Text style={styles.label}>[주문내역]</Text>
      </View>

      {invoice.excelOrders.length > 0 && (
        <View style={styles.subTitle}>
          <Text style={styles.label}>
            엑셀주문({invoice.excelOrders.length} 건)
          </Text>
        </View>
      )}

      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={[styles.tableCol, styles.tableCell, { width: "40px" }]}>
            No
          </Text>
          <Text style={[styles.tableCol, styles.tableCell, { width: "40%" }]}>
            DESCRIPTION
          </Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Order Date</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>
            Delivered Date
          </Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Weight</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Delivery Fee</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Amount</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>HAWB</Text>
        </View>
        {invoice.excelOrders.map((order, index) => (
          <View style={styles.tableRow} key={index}>
            <Text
              style={[styles.tableCol, styles.tableCellSm, { width: "40px" }]}
            >
              {index + 1}
            </Text>
            <Text
              style={[styles.tableCol, styles.tableCellSm, { width: "40%" }]}
            >
              {order.order_product.map((product, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={[styles.tableCol, styles.tableCellSm]}>
                    {product.productName}
                  </Text>
                  <Text style={[styles.tableCol, styles.tableCellSm]}>
                    {product.qauntity}
                  </Text>
                </View>
              ))}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {order.created_at.slice(0, 10)}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {order.delivery_at.slice(0, 10)}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {order.weight}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {order.delivery_fee}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {(
                order.transaction_info.credit +
                order.transaction_info.deposit +
                order.transaction_info.point
              ).toFixed(2)}
            </Text>
            <Text style={[styles.tableCol, styles.tableCellSm]}>
              {order.hawb}
            </Text>
          </View>
        ))}
      </View>

      {invoice.pickupOrders.length > 0 && (
        <View style={styles.subTitle}>
          <Text style={styles.label}>
            {" "}
            픽업주문({invoice.pickupOrders.length} 건)
          </Text>
        </View>
      )}

      <View style={styles.table}>
        {/* <View style={styles.tableHeader}>
          <Text style={[styles.tableCol, styles.tableCell]}>QTY</Text>
          <Text style={[styles.tableCol, styles.tableCell, { width: "40%" }]}>
            PRODUCT DESCRIPTION
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "right" }]}
          >
            PRICE
          </Text>
          <Text
            style={[styles.tableCol, styles.tableCell, { textAlign: "right" }]}
          >
            TOTAL
          </Text>
        </View> */}
        {/* {invoice.items.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={[styles.tableCol, styles.tableCell]}>{item.qty}</Text>
            <Text style={[styles.tableCol, styles.tableCell, { width: "40%" }]}>
              {item.description}
            </Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "right" },
              ]}
            >
              ${item.price.toFixed(2)}
            </Text>
            <Text
              style={[
                styles.tableCol,
                styles.tableCell,
                { textAlign: "right" },
              ]}
            >
              ${(item.qty * item.price).toFixed(2)}
            </Text>
          </View>
        ))} */}
      </View>

      {/* <View style={styles.total}>
        <Text style={styles.totalLabel}>Subtotal</Text>
        <Text style={styles.totalValue}>${invoice.subtotal.toFixed(2)}</Text>
      </View>
      <View style={styles.total}>
        <Text style={styles.totalLabel}>Tax Rate</Text>
        <Text style={styles.totalValue}>{invoice.taxRate}%</Text>
      </View>
      <View style={styles.total}>
        <Text style={styles.totalLabel}>TOTAL</Text>
        <Text style={styles.totalValue}>${invoice.total.toFixed(2)}</Text>
      </View> */}

      <View style={styles.footer}>
        <Text>THANK YOU FOR YOUR BUSINESS</Text>
        <Text>Payment is due max 15 days after invoice</Text>
        <Text>Account Name: Health Inside Limited</Text>
        <Text>Account Number: ANZ BANK 06-0193-0837308-00</Text>
        <Text>Swift Code: ANZBNZ22XXX </Text>
      </View>
    </Page>
  </Document>
);

export default InvoiceForm;
