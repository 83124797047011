// import axios from "axios";

export const excelUpdate = (rows) => {
  // 업데이트 대상 주문건 분석 : 운송장번호, 무게, 배송비가 기재된 주문건 확인
  // 1단계 : 필수 필드 유무 확인
  // 2단계 : 전체 및 업체별 발송완료 대상건 추출
  let tempOrderNo = "";
  let tempNo = "";
  let tempOrderNoArr = [];
  let tempItems = [];
  let tempOrdersObj = {};
  let tempDirectOrder = []; // 수기로 등록한 주문건

  let tempWholesaleObj = {};

  let checkField = [];

  //  1단계 : 필수 필드 유무 확인
  const fieldIndex = setFieldIndex(rows[0]); //필드별 인덱스 설정
  checkField = findNullKeys(fieldIndex);

  // 2단계 : 전체 및 업체별 발송완료 대상건 추출
  rows.map((row, idx) => {
    try {
      // 엑셀파일 2번째 행부터 각각의 필요한 필드 저장하기
      if (idx !== 0) {
        let no = row[fieldIndex["no"]];
        let orderNo = row[fieldIndex["orderNo"]];
        let hawb = row[fieldIndex["hawb"]];
        let weight = row[fieldIndex["weight"]];
        let deliveryFee = row[fieldIndex["deliveryFee"]];
        let wholesaler = row[fieldIndex["wholesaler"]];
        let origin = row[fieldIndex["origin"]];

        // 주문된 제품 내용
        let item = {
          productCode: row[fieldIndex["item"]["productCode"]],
          productName: row[fieldIndex["item"]["productName"]],
          qauntity: row[fieldIndex["item"]["qauntity"]],
          unitPrice: row[fieldIndex["item"]["unitPrice"]],
        };

        // 주문고유번호가 있는 경우 object를 만들고 주문정보를 추가하고, 값이 없는 경우 이미 만들어진 key에 아이템만 추가한다.
        if (no && orderNo) {
          // 발송완료 변경이 가능한 주문건들 수집
          tempItems = []; //임시 items 초기화

          if (hawb && weight) {
            tempOrderNo = orderNo; //임시 orderNo 설정
            tempNo = no; //임시 no 설정
            tempItems.push(item);
            tempOrderNoArr.push(orderNo);

            // 예외처리 : 만약 주문자 번호만 있고, 주문자 정보가 없는 경우
            let customerName = row[fieldIndex["customerName"]];
            let pcc = row[fieldIndex["pcc"]];
            let customerTel = row[fieldIndex["customerTel"]];
            let customerMobile = row[fieldIndex["customerMobile"]];
            let zipCode = row[fieldIndex["zip"]].toString();
            if (zipCode.length === 4) {
              zipCode = "0" + zipCode;
            }
            let address = row[fieldIndex["address"]];
            let deliveryMemo = row[fieldIndex["memo"]];

            let order = {
              orderNo,
              customerName,
              customerTel,
              customerMobile,
              pcc,
              zipCode,
              address,
              deliveryMemo,
              items: tempItems,
              weight,
              hawb,
              deliveryFee,
              origin,
              wholesaler,
            };

            // 주문정보 담기
            // 수기건 분류
            tempOrdersObj[orderNo] = order;

            if (tempWholesaleObj.hasOwnProperty(wholesaler)) {
              tempWholesaleObj[wholesaler].push(orderNo);
            } else {
              tempWholesaleObj[wholesaler] = [orderNo];
            }
          } else if (hawb && hawb.trim() === "취소") {
            tempOrderNo = orderNo; //임시 orderNo 설정
            tempItems.push(item);
            tempOrderNoArr.push(orderNo);

            // 예외처리 : 만약 주문자 번호만 있고, 주문자 정보가 없는 경우
            let customerName = row[fieldIndex["customerName"]];
            let pcc = row[fieldIndex["pcc"]];
            let customerTel = row[fieldIndex["customerTel"]];
            let customerMobile = row[fieldIndex["customerMobile"]];
            let zipCode = row[fieldIndex["zip"]].toString();
            if (zipCode.length === 4) {
              zipCode = "0" + zipCode;
            }
            let address = row[fieldIndex["address"]];
            let deliveryMemo = row[fieldIndex["memo"]];

            let order = {
              orderNo,
              customerName,
              customerTel,
              customerMobile,
              pcc,
              zipCode,
              address,
              deliveryMemo,
              items: tempItems,
              weight: null,
              hawb: hawb,
              deliveryFee: null,
            };

            // 주문정보 담기
            tempOrdersObj[orderNo] = order;

            if (tempWholesaleObj.hasOwnProperty(wholesaler)) {
              tempWholesaleObj[wholesaler].push(orderNo);
            } else {
              tempWholesaleObj[wholesaler] = [orderNo];
            }
          } else {
            tempOrderNo = "notTheTarget";
          }
        } else if (no && !orderNo) {
          console.log("주문번호 없는 번호", no);
          tempNo = "notTheTarget";
        } else {
          // 주문번호, 번호가 없는 제품정보만 있는 열의 경우 해당 
          if (tempOrderNo !== "notTheTarget" && tempNo !== "notTheTarget") {
            // console.log("orderno", tempOrderNo);
            tempItems.push(item);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  });

  console.log("주문object", tempOrdersObj);
  console.log("업체별주문", tempWholesaleObj);
  console.log("주문번호 :", tempOrderNoArr);

  return {
    ordersObj: tempOrdersObj,
    wholesaleObj: tempWholesaleObj,
    ordersArr: tempOrderNoArr,
  };
};

// null값이 있는 key값 찾는 함수
function findNullKeys(obj, prefix = "") {
  let keysWithNullValue = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (value === null) {
      keysWithNullValue.push(prefixedKey);
    } else if (typeof value === "object" && value !== null) {
      keysWithNullValue = keysWithNullValue.concat(
        findNullKeys(value, prefixedKey)
      );
    }
  });
  return keysWithNullValue;
}

// 필드별 인덱스 번호 설정
export const setFieldIndex = (row) => {
  // 필수필드 인덱스
  let fieldIndex = {
    orderNo: null,
    wholesaler: null,
    origin: null,
    customerName: null,
    customerTel: null,
    customerMobile: null,
    pcc: null,
    zip: null,
    address: null,
    memo: null,
    item: {
      productCode: null,
      productName: null,
      qauntity: null,
      unitProduct: null,
    },
    hawb: null,
    weight: null,
    deliveryFee: null,
  };

  row.map((field, idx) => {
    switch (field) {
      case "번호":
        fieldIndex["no"] = idx;
        break;
      case "주문번호":
        fieldIndex["orderNo"] = idx;
        break;
      case "회사코드":
        fieldIndex["wholesaler"] = idx;
        break;
      case "추가서류":
        fieldIndex["origin"] = idx;
        break;
      case "이름":
        fieldIndex["customerName"] = idx;
        break;
      case "전화번호":
        fieldIndex["customerTel"] = idx;
        break;
      case "모바일번호":
        fieldIndex["customerMobile"] = idx;
        break;
      case "통관부호":
        fieldIndex["pcc"] = idx;
        break;
      case "우편번호":
        fieldIndex["zip"] = idx;
        break;
      case "주소":
        fieldIndex["address"] = idx;
        break;
      case "배송메모":
        fieldIndex["memo"] = idx;
        break;
      case "재고관리코드":
        fieldIndex["item"]["productCode"] = idx;
        break;
      case "영문상품명":
        fieldIndex["item"]["productName"] = idx;
        break;
      case "수량":
        fieldIndex["item"]["qauntity"] = idx;
        break;
      case "단가":
        fieldIndex["item"]["unitPrice"] = idx;
        break;
      case "HAWB":
        fieldIndex["hawb"] = idx;
        break;
      case "무게":
        fieldIndex["weight"] = idx;
        break;
      case "배송비":
        fieldIndex["deliveryFee"] = idx;
        break;

      default:
        break;
    }
  });

  return fieldIndex;
};
