import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Form,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

import { Cookies } from "react-cookie";

const cookies = new Cookies();

// 기간 선택 모달 컴포넌트
const DateRangeModal = ({ isOpen, toggle, onApply }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeButton, setActiveButton] = useState("최대(1년)");

  const handleButtonClick = (period) => {
    setActiveButton(period);
    const end = new Date();
    let start = new Date();

    switch (period) {
      case "1개월":
        start.setMonth(end.getMonth() - 1);
        break;
      case "3개월":
        start.setMonth(end.getMonth() - 3);
        break;
      case "6개월":
        start.setMonth(end.getMonth() - 6);
        break;
      default: // 최대(1년)
        start.setFullYear(end.getFullYear() - 1);
    }

    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>조회 기간 선택</ModalHeader>
      <ModalBody>
        <ButtonGroup className="mb-3 d-flex">
          {["1년", "1개월", "3개월", "6개월"].map((period) => (
            <Button
              key={period}
              color={activeButton === period ? "primary" : "secondary"}
              onClick={() => handleButtonClick(period)}
              className="flex-grow-1"
            >
              {period}
            </Button>
          ))}
        </ButtonGroup>
        <Form>
          <FormGroup className="d-flex align-items-center">
            <div style={{ flex: 1, marginRight: "10px" }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy.MM.dd"
                className="form-control"
              />
            </div>
            <span style={{ margin: "0 10px" }}>~</span>
            <div style={{ flex: 1, marginLeft: "10px" }}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy.MM.dd"
                className="form-control"
              />
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onApply(startDate, endDate)}>
          조회하기
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// 포인트 내역 테이블 컴포넌트
const PointHistoryTable = ({ data }) => (
  <Table striped>
    <thead>
      <tr>
        <th>날짜</th>
        <th>업체</th>
        <th>내용</th>
        <th>포인트</th>
        <th>메모</th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((item, index) => (
          <tr key={index}>
            <td>
              {" "}
              <Moment format="YYYY-MM-DD">{item.created_at}</Moment>
            </td>
            <td>{item.wholesaler}</td>
            <td>
              {item.deposit_index && `선불충전금 포인트(${item.deposit_index})`}
              {item.order_no && `엑셀주문(${item.order_no})`}
              {item.pickup_order_no && `픽업주문(${item.pickup_order_no})`}
            </td>
            <td className={item.amount >= 0 ? "text-success" : "text-danger"}>
              {item.amount >= 0 ? "+" : ""}
              {item.amount}
            </td>
            <td>{item.transaction_memo}</td>
          </tr>
        ))}
    </tbody>
  </Table>
);

function AdminPoint({ isActive }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [activeTab, setActiveTab] = useState("적립");
  const [allPointHistory, setAllPointHistory] = useState([]);

  useEffect(() => {
    // 포인트 리스트 가져오기
    if (isActive) {
      getPoint();
    }
  }, [isActive]);

  const getPoint = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/point`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      console.log("원본", resList.data.data.list);
      setAllPointHistory(resList.data.data.list);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleApplyDateRange = (start, end) => {
    setDateRange({ start, end });
    toggleModal();
  };

  // 포인트 유형에 따라 필터링된 데이터
  const filteredPointHistory = useMemo(() => {
    switch (activeTab) {
      case "포인트 적립":
        return allPointHistory.filter((item) => item.amount > 0);
      case "포인트 사용":
        return allPointHistory.filter((item) => item.amount < 0);
      default:
        return allPointHistory;
    }
  }, [activeTab, allPointHistory]);

  return (
    <>
      <Row className="mb-3">
        <Col className="p-5 border text-center">검색 영역</Col>
      </Row>

      <Nav tabs className="mb-3">
        {["포인트 적립", "포인트 사용"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <PointHistoryTable data={filteredPointHistory} />
    </>
  );
}

export default AdminPoint;
