export const getPoint = (deposit) => {
  let point = 0.0;

  if (deposit >= 1000 && deposit < 5000) {
    point = parseFloat(deposit * 0.05).toFixed(2);
  } else if (deposit >= 5000 && deposit < 10000) {
    point = parseFloat(deposit * 0.1).toFixed(2);
  } else if (deposit >= 10000) {
    point = parseFloat(deposit * 0.15).toFixed(2);
  } else {
    point = 0;
  }

  // 충전금 대비 적립할 포인트 전달
  return point;
};
