import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Label,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

function MainSearchForm() {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  const handleMovePage = () => {
    if (keyword === "") {
      alert("검색어를 입력해 주세요.");
      return;
    }
    navigate(`/search/${keyword}`);
  };

  return (
    <Row>
      <Col className="text-start">
        <Form onSubmit={handleMovePage}>
          <FormGroup>
            <Label for="keyword">
              주문번호, 고객명, 연락처, 통관부호, 운송장번호로 검색하세요.
            </Label>
            <InputGroup className="text-center">
              <Input
                type="text"
                id="keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="검색어를 입력해주세요."
              />
              <Button type="submit" color="primary">
                검색
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
}

export default MainSearchForm;
