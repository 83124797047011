import React, { useState, useEffect } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import { orderStatus } from "../../../utils/ConvertWord";
import { handlePrintPackingSlip } from "../../print/PackingSlip";

const cookies = new Cookies();

function PickupCheck({ selectedOrder, setSelectedOrder }) {
  // 메모 정보 변경 핸들러
  const handleChangeMemo = (event) => {
    setSelectedOrder({ ...selectedOrder, memo: event.target.value });
  };

  const [products, setProducts] = useState(selectedOrder.order_product);

  return (
    <>
      <div className="m-2">
        <h5>주문개요</h5>
      </div>
      <div className="m-2 border-bottom">
        <Row>
          <Col>
            <Form>
              <Row>
                <Col md={6}>- 업체코드 : {selectedOrder.wholesaler}</Col>
                <Col md={6}>
                  - 등록일 :{" "}
                  <Moment format="YYYY-MM-DD HH:mm">
                    {selectedOrder.created_at}
                  </Moment>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="memo">- 메모</Label>
                    <Input
                      type="textarea"
                      name="memo"
                      value={selectedOrder.memo}
                      id="memo"
                      onChange={(e) => handleChangeMemo(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <div className="m-2">
        <Row>
          <Col>
            <h5>제품 정보</h5>
          </Col>
          <Col className="text-end">
            <Button
              color="warning"
              size="sm"
              onClick={() => handlePrintPackingSlip(selectedOrder)}
            >
              Packing Slip Print
            </Button>
          </Col>
        </Row>
      </div>
      <div className="m-2">
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Table bordered>
                      <thead>
                        <tr className="table-primary">
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            제품코드
                          </th>
                          <th className="text-center">제품명</th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            수량
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            단가
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            총액
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {product.productCode}
                              </span>
                            </td>
                            <td>
                              <span style={{ fontSize: "16px" }}>
                                {product.productName}
                              </span>
                            </td>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {product.qauntity}
                              </span>
                            </td>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {product.unitPrice}
                              </span>
                            </td>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {(product.unitPrice * product.qauntity).toFixed(
                                  2
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PickupCheck;
