import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../../context/AuthContext";
import { Cookies } from "react-cookie";
import { orderStatus } from "../../utils/ConvertWord";
const cookies = new Cookies();

function UploadList() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [orderListData, setOrderListData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [count, setCount] = useState(0);

  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정

  const [selectedExcel, setSelectedExcel] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글

  const toggleTooltip = (receiver) => {
    setTooltipOpen({
      ...tooltipOpen,
      [receiver]: !tooltipOpen[receiver],
    });
  };

  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  useEffect(() => {
    const excelListData = async () => {
      try {
        // 업로드 목록 가져오기
        const encodedWholesaler = encodeURIComponent(
          cookies.get("info").profile
        );

        const resList = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/wholesale/excel/list?page=${currentPage}&limit=${pageSize}&wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
        setData(resList.data.data.list);
        setCount(resList.data.data.count.count);

        setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
        setData(null);
      }
    };

    excelListData();
  }, [currentPage, pageSize]);

  const getOrderList = async (excelIndex) => {
    try {
      const encodedWholesaler = encodeURIComponent(cookies.get("info").profile);
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/wholesale/excel/order/list?excelIndex=${excelIndex}&wholesaler=${encodedWholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      setOrderListData(resList.data.data.list);
      setSelectedExcel(excelIndex);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
      setOrderListData(null);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (p) => {
    setCurrentPage(p.selected);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });

    return amount;
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    // let resValue = null;
    // let pcode = productCode.toUpperCase();

    // // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    // if (pcode === "BRING" || pcode === "PROMO") {
    //   resValue = "$0";
    // } else if (
    //   pcode === "CHEMIST" ||
    //   pcode === "MART" ||
    //   pcode === "SPECIAL" ||
    //   pcode === ""
    // ) {
    //   resValue = "미정";
    // } else {
    //   resValue =
    //     "$" +
    //     parseFloat(unitPrice * qauntity)
    //       .toFixed(2)
    //       .toString();
    // }

    // return resValue;
    let resValue =
      "$" + (parseFloat(unitPrice) * qauntity).toFixed(2).toString();

    return resValue;
  };

  return (
    <NormalLayout>
      <div>
        <h3>엑셀주문목록({count})</h3>
      </div>
      <div className="mt-3">
        {data ? (
          <>
            <div className="border p-3">
              <div className="mb-3">
                {/* <div className="text-center p-5  mb-3 border">검색 설정</div>{" "} */}
                <Row>
                  <Col>
                    {" "}
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle caret color="dark">
                        페이지당 노출 수: {pageSize}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => selectPageSize(5)}>
                          5개
                        </DropdownItem>
                        <DropdownItem onClick={() => selectPageSize(10)}>
                          10개
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
              <Table hover bordered>
                <thead>
                  <tr className="table-primary">
                    <th className="text-center">업로드 번호</th>
                    <th className="text-center">회사코드</th>
                    <th className="text-center">담당자</th>
                    <th className="text-center">메모</th>
                    <th className="text-center">등록일</th>
                    <th className="text-center">주문건수</th>
                    <th className="text-center">기능</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((excel, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-center">
                          {excel.order_excel_index}
                        </td>
                        <td className="text-center">{excel.wholesaler}</td>
                        <td className="text-center">{excel.email}</td>
                        <td className="text-center">{excel.excel_memo}</td>
                        <td className="text-center">
                          <Moment format="YYYY/MM/DD HH:mm">
                            {excel.created_at}
                          </Moment>
                        </td>
                        <td className="text-center">{excel.order_count}</td>
                        <td className="text-center">
                          <Button
                            color="primary"
                            onClick={() =>
                              getOrderList(excel.order_excel_index)
                            }
                          >
                            목록보기
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ReactPaginate
                  previousLabel={"이전"}
                  nextLabel={"다음"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"item active "}
                  breakClassName={"item break-me "}
                  pageClassName={"item pagination-page "}
                  disabledClassName={"disabled-page"}
                />
              </div>
            </div>
          </>
        ) : (
          <>주문 데이터 없음</>
        )}
      </div>
      <div className="mt-5">
        {" "}
        {orderListData && orderListData.length > 0 && (
          <>
            <h4 className="mt-3">
              {" "}
              상세주문 목록 ({orderListData.length}건){" "}
              {selectedExcel && selectedExcel}
            </h4>

            <Table hover bordered className="mt-3">
              <thead className="fontsize_14px">
                <tr className="table-primary">
                  <th className="text-center p-2 border">주문번호</th>
                  <th className="text-center p-2 border">이름</th>
                  {/* <th className="text-center p-2 border">전화번호</th>
                  <th className="text-center p-2 border">모바일번호</th>
                  <th className="text-center p-2 border">통관부호</th>
                  <th className="text-center p-2 border">우편번호</th>
                  <th className="text-center p-2 border">주소</th> */}
                  <th className="text-center p-2 border">배송메모</th>
                  <th className="text-center p-2 border">주문상품</th>
                  {/* <th className="text-center p-2 border">
                    예상금액
                    <br />
                    (배송비 제외)
                  </th> */}
                  <th className="text-center p-2 border">주문상태</th>
                </tr>
              </thead>
              <tbody className="fontsize_12px">
                {orderListData.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center" style={{ cursor: "pointer" }}>
                        <span id={`Tooltip-${o.orderIndex}`}>
                          {o.customer_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[o.orderIndex]}
                          target={`Tooltip-${o.orderIndex}`}
                          toggle={() => toggleTooltip(o.orderIndex)}
                        >
                          <div className="text-start">
                            <li>통관부호 : {o.pcc}</li>
                            <li>전화번호 : {o.customer_tel}</li>
                            <li>모바일 : {o.customer_mobile}</li>
                            <li>통관부호 : {o.pcc}</li>

                            <li>
                              주소 : {o.address}, #{o.zip}
                            </li>
                          </div>
                        </Tooltip>
                      </td>
                      {/* <td className="text-center">{o.customer_tel}</td>
                      <td className="text-center">{o.customer_mobile}</td>
                      <td className="text-center">{o.pcc}</td>

                      <td className="text-center">{o.zip}</td>
                      <td>{o.address}</td> */}

                      <td>{o.delivery_memo}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      {/* <td className="text-center">
                        ${genAmount(o.order_product)}
                      </td> */}
                      <td className="text-center">
                        {orderStatus(o.order_status)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </div>
    </NormalLayout>
  );
}

export default UploadList;
