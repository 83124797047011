import { useState } from "react";
import { forgotPassword } from "../components/auth/auth";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, FormGroup, Input, Button } from "reactstrap";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await forgotPassword(email).then(() => {
        alert("이메일에서 코드를 확인하세요.");
        setSuccess(true);
        navigate(`/reset-password/${email}`);
      });
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div>
        <h2>비밀번호 재설정</h2>
        <p>비밀번호 재설정을 위한 코드를 이메일에서 확인하세요.</p>
      </div>
    );
  }

  return (
    <Container className="login-container">
      <div className="p-5 square border border-primary rounded">
        {" "}
        <h2 className="mb-3">비밀번호 재설정</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <Button type="submit" color="primary" block>
            확인
          </Button>
        </Form>
        {error && <p>{error}</p>}
        <div className="p-3 text-center">
          <Link to="/login">로그인</Link>
        </div>
      </div>
    </Container>
  );
}
