import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import AddProduct from "./AddProduct";
import { orderStatus } from "../../../utils/ConvertWord";

const cookies = new Cookies();

function PickupOrderForm({ selectedOrder, setSelectedOrder }) {
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [newProducts, setNewProducts] = useState(selectedOrder.order_product);

  const showAddProductFormToggle = () =>
    setShowAddProductForm(!showAddProductForm);

  // 메모 정보 변경 핸들러
  const handleChangeMemo = (event) => {
    setSelectedOrder({ ...selectedOrder, memo: event.target.value });
  };

  const [products, setProducts] = useState(selectedOrder.order_product);

  // 제품 정보 변경 핸들러
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newProducts = [...products];
    newProducts[index][name] = value;
    setProducts(newProducts);
    console.log(selectedOrder, newProducts);
    setSelectedOrder({ ...selectedOrder, orderStatus: newProducts });
  };

  //   제품 추가
  const handelAddProductBtn = () => {
    // 추가제품 초기화
    setNewProducts([]);
    setShowAddProductForm(true);
  };

  // 새 제품 추가 핸들러
  const handleAddProduct = (p) => {
    let convertedNewProducts = [];
    p.map((item) => {
      convertedNewProducts.push({
        qauntity: item.qauntity,
        unitPrice: item.price,
        productCode: item.product_code,
        productName: item.en_name,
      });
    });

    let finalProducts = [...convertedNewProducts, ...products];
    setProducts(finalProducts); // 새로운 제품을 리스트의 맨 위에 추가

    setSelectedOrder({ ...selectedOrder, order_product: finalProducts });
    setShowAddProductForm(false);
  };
  // 제품 삭제 핸들러
  const handleRemoveProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
    setSelectedOrder({ ...selectedOrder, order_product: newProducts });
  };

  return (
    <>
      <Modal
        isOpen={showAddProductForm}
        toggle={showAddProductFormToggle}
        className="custom-modal"
      >
        <ModalHeader toggle={showAddProductFormToggle}>
          제품 추가하기
        </ModalHeader>
        <ModalBody>
          <AddProduct
            newProducts={newProducts}
            setNewProducts={setNewProducts}
            wholesaler={selectedOrder.wholesaler}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleAddProduct(newProducts)}>
            추가
          </Button>
          <Button color="secondary" onClick={showAddProductFormToggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>
      <h5>주문개요</h5>
      <div className="m-2 border-bottom">
        <Row>
          <Col>
            <Form>
              <Row>
                <Col md={6}>- 업체코드 : {selectedOrder.wholesaler}</Col>
                <Col md={6}>
                  - 등록일 :{" "}
                  <Moment format="YYYY-MM-DD HH:mm">
                    {selectedOrder.created_at}
                  </Moment>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="memo">- 요청메모</Label>
                    <Input
                      type="textarea"
                      name="memo"
                      value={selectedOrder.memo}
                      id="memo"
                      onChange={(e) => handleChangeMemo(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>

      <h5>제품 정보</h5>

      <div className="m-2">
        <Row>
          <Col className="text-end">
            <Button
              color="success"
              onClick={() => handelAddProductBtn()}
              className="mb-3"
            >
              + 제품 추가
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            제품코드
                          </th>
                          <th className="text-center">제품명</th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            수량
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            단가
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            총액
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            기능
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {product.productCode}
                              </span>
                            </td>
                            <td>
                              <span style={{ fontSize: "16px" }}>
                                {product.productName}
                              </span>
                            </td>
                            <td>
                              <Input
                                type="number"
                                min={1}
                                name="qauntity"
                                value={product.qauntity}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="number"
                                name="unitPrice"
                                value={product.unitPrice}
                                step={0.01}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                              />
                            </td>
                            <td className="text-center">
                              <span style={{ fontSize: "16px" }}>
                                {(product.unitPrice * product.qauntity).toFixed(
                                  2
                                )}
                              </span>
                            </td>
                            <td className="text-center">
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() => handleRemoveProduct(index)}
                              >
                                삭제
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PickupOrderForm;
