import moment from "moment";
import { Cookies } from "react-cookie";
import info from "../../assets/info/packingSlipInfo.json";
import axios from "axios";

const cookies = new Cookies();

export const handlePrintMonthlyInvoice = async (invoice) => {
  console.log("인보이스 데이터", invoice);

  const printWindow = window.open("", "_blank", "width=800,height=900");

  printWindow.document.write(`
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>TAX INVOICE</title>
    <style>
     @media print {
            body {
                width: 210mm;
                height: 297mm;
                margin: 7mm;
                padding: 7mm;
                font-size: small; /* 인쇄 시 폰트 크기를 12pt로 설정 */
                line-height: 1.2; /* 줄 간격을 1.5배로 설정 */
            }

  
        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .header-section {
            width: 48%;
        }

        .border-box {
            border: 1px solid #000;
            padding: 15px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0px;
        }

        th, td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
        }


        .product-td {
            border: 1px solid #000;
            padding: 0px;
            text-align: left;
        }

        .summary-table td:last-child,
        .summary-table th:last-child {
            text-align: right;
        }

        .order-details td {
            vertical-align: top;
        }

        .product-details {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .product-details li {
            margin-bottom: 5px;
        }

        .footer {
            border-top: 2px solid #000;
            padding-top: 20px;
        }
        } 

        @media screen {
            .invoice {
                max-width: 210mm;
                margin: 0 auto;
                padding: 20px;
                border: 1px solid #ddd;
            }

            table {
            border-collapse: collapse;
            border: 0;
            }

            th, td {
            border: 1px solid #ddd; /* 테두리 색상과 두께 설정 */
            padding: 8px; /* 셀 안쪽 여백 설정 */
            }
        }

         .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .header-section {
            width: 48%;
        }

        .border-box {
            border: 1px solid #000;
            padding: 15px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0px;
        }

        th, td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
            font-size:10px;
        }

        th {
            background-color: #cfe2ff;
        }

        .product-td {
            border: 1px solid #000;
            padding: 0px;
            text-align: left;
        }


        .summary-table td:last-child,
        .summary-table th:last-child {
            text-align: right;
        }

        .no-border {
          border: none; /* 테이블 전체 테두리 없애기 */
          padding: 0px;
        }

        .order-details td {
            vertical-align: top;
        }

        .product-details {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .product-details li {
            margin-bottom: 5px;
        }

        .footer {
            border-top: 2px solid #000;
            padding-top: 20px;
        }
    </style>
</head>

<body>
    <div class="invoice">
        <h1>TAX INVOICE</h1>

    <!-- 첫번째 파트: 헤더 정보 -->
    <div class="header">
        <div class="header-section border-box">
            <h3>Bill To</h3>
            <p>${invoice.billTo.name}</p>
            <p>Date : ${invoice.date}</p>
        </div>
        <div class="header-section border-box">
            <h3>Health Inside Limited</h3>
            <p>
            Unit 7, 7 Henry Rose Place<br/>
            Albany <br/>
            Auckland <br/>
            New Zealand <br/>
            0632
            </p>
        </div>
    </div>

    <!-- 두번째 파트: 요약 정보 -->
    <h3>Summary</h3>
    <table class="summary-table">
        <thead>
            <tr>
                <th>DESCRIPTION</th>
                <th>PRICE</th>
                <th>AMOUNT</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>PRODUCT ${invoice.gst ? "(GST included)" : ""}</td>
                <td>$${invoice.summary.amountProductPrice}</td>
                <td>$${invoice.summary.amountProductPrice}${
    invoice.gst ? `(GST : $${invoice.summary.gst})` : ""
  }</td>
            </tr>
            <tr>
                <td>Shipping</td>
                <td>$${invoice.summary.amountDeliveryFee}</td>
                <td>$${invoice.summary.amountDeliveryFee}</td>
            </tr>    
            ${
              invoice.summary.amountPoint !== 0
                ? `<tr>
                  <td>Discount(Point)</td>
                  <td>-$${invoice.summary.amountPoint.toFixed(2)}</td>
                  <td>-$${invoice.summary.amountPoint.toFixed(2)}${
                    invoice.gst
                      ? `(GST : -$${(
                          invoice.summary.amountPoint -
                          invoice.summary.amountPoint / 1.15
                        ).toFixed(2)})`
                      : ""
                  }</td>
                </tr>`
                : ""
            }
            <tr style="background-color: #cfe2ff">
                <td>Total NZD</td>
                <td></td>
                <td>$${invoice.summary.totalAmount}</td>
            </tr>
            ${
              invoice.summary.amountDeposit !== 0
                ? `<tr>
                  <td>Less Amount Credited(Deposit)</td>
                  <td></td>
                  <td>$${invoice.summary.amountDeposit.toFixed(2)}</td>
                </tr>`
                : ""
            }
            <tr style="background-color: #cfe2ff">
                <td><strong>Amount Due NZD</strong></td>
                <td></td>
                <td><strong>$${invoice.summary.amountCredit.toFixed(
                  2
                )}</strong></td>
            </tr>
        </tbody>
    </table>

    <!-- 세번째 파트: 주문 내역 -->
    <br/><br/>
        <h3>[주문내역]</h3>
         ${
           invoice.excelOrders.length !== 0
             ? `
            <h4>엑셀주문</h4>
            <table class="order-details">
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>주문제품(제품명, 수량, 단가, 총액)</th>
                    <th style="width:55px">주문일</th>
                    <th style="width:55px">출고일</th>
                    <th>무게</th>
                    <th>배송료</th>
                    <th style="width:100px">총금액</th>
                    <th>운송장번호</th>
                  </tr>
                </thead>
                <tbody>
            ${invoice.excelOrders
              .map(
                (order, idx) =>
                  `
                  <tr>
                    <td>${idx + 1}</td>
                    <td class='product-td'>
                        <table class="order-details">
                        <tbody>
                        ${order.order_product
                          .map(
                            (product) =>
                              `<tr>
                                <td  style="width:190px">${
                                  product.productName
                                }</td>
                                <td>${product.qauntity}</td>
                                <td>${product.unitPrice}</td>
                                <td>${(
                                  parseFloat(product.unitPrice) *
                                  parseInt(product.qauntity)
                                ).toFixed(2)}</td>
                            </tr>`
                          )
                          .join("")}
                        </tbody>
                        </table>
                    </td>
                    <td>${order.created_at.slice(0, 10)}</td>
                    <td>${order.delivery_at.slice(0, 10)}</td>
                    <td>${order.weight}</td>
                    <td>${order.delivery_fee}</td>
                    <td>$${(
                      order.transaction_info.credit +
                      order.transaction_info.deposit +
                      order.transaction_info.point
                    ).toFixed(2)}<br/>
                      ${
                        order.transaction_info.credit !== 0
                          ? `<li>여신 : $${order.transaction_info.credit}</li>`
                          : ""
                      }
                        ${
                          order.transaction_info.deposit !== 0
                            ? `<li>
                              선불충전금 : $${order.transaction_info.deposit}
                            </li>`
                            : ""
                        }
                        ${
                          order.transaction_info.point !== 0
                            ? `<li>포인트 : $${order.transaction_info.point}</li>`
                            : ""
                        }
                    
                      </td>
                    <td><a href=${`https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${order.hawb}`} target="_blank">${
                    order.hawb
                  }</a></td>
                  </tr>
                `
              )
              .join("")}
                </tbody>
              </table>
        `
             : ""
         }

          ${
            invoice.pickupOrders.length !== 0
              ? `
            <h4>픽업주문</h4>
            <table class="order-details">
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>주문제품(제품명, 수량, 단가, 총액)</th>
                    <th>주문일</th>
                    <th>픽업일</th>
                    <th>총금액</th>
                  </tr>
                </thead>
                <tbody>
            ${invoice.pickupOrders
              .map(
                (order, idx) =>
                  `
                  <tr>
                    <td>${idx + 1}</td>
                    <td class='product-td'>
                        <table class="summary-table no-border">
                        <tbody>
                        ${order.order_product
                          .map(
                            (product) =>
                              `<tr>
                                <td style="width:250px">${
                                  product.productName
                                }</td>
                                <td>${product.qauntity}</td>
                                <td>${product.unitPrice}</td>
                                 <td>${(
                                   parseFloat(product.unitPrice) *
                                   parseInt(product.qauntity)
                                 ).toFixed(2)}</td>
                            </tr>`
                          )
                          .join("")}
                        </tbody>
                        </table>
                    </td>
                    <td>${order.created_at.slice(0, 10)}</td>
                    <td>${order.pickup_at.slice(0, 10)}</td>
                    <td>$${(
                      order.transaction_info.credit +
                      order.transaction_info.deposit +
                      order.transaction_info.point
                    ).toFixed(2)}<br/>
                      ${
                        order.transaction_info.credit !== 0
                          ? `<li>여신 : $${order.transaction_info.credit}</li>`
                          : ""
                      }
                        ${
                          order.transaction_info.deposit !== 0
                            ? `<li>
                              선불충전금 : $${order.transaction_info.deposit}
                            </li>`
                            : ""
                        }
                        ${
                          order.transaction_info.point !== 0
                            ? `<li>포인트 : $${order.transaction_info.point}</li>`
                            : ""
                        }</td>
                  </tr>
                `
              )
              .join("")}
                </tbody>
              </table>
        `
              : ""
          }
   

    <!-- 네번째 파트: 정산 안내 -->
    <div class="footer" style="text-align: center;">
    <br/><br/><br/>
    <p>
        THANK YOU FOR YOUR BUSINESS<br/>
        Payment is due max 15 days after invoice<br/>
        Account Name: Health Inside Limited<br/>
        Account Number: ANZ BANK 06-0193-0837308-00<br/>
        Swift Code: ANZBNZ22XXX<br/>
    </p>

    </div>

    </div>
</body>
</html>
`);
  printWindow.document.close();
};
