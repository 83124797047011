import React from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import logo from "../../assets/img/logo.png";
import transfer1 from "../../assets/img/transfer1.jpg";
import transfer2 from "../../assets/img/transfer2.jpg";
import transfer3 from "../../assets/img/transfer3.jpg";
import transfer4 from "../../assets/img/transfer4.jpg";
import transfer5 from "../../assets/img/transfer5.jpg";
import transfer6 from "../../assets/img/transfer6.jpg";
import Footer from "../../components/layout/Footer";
import Account from "../../assets/info/account.json";

function TransferToNZ() {
  return (
    <>
      <div className="text-center p-5">
        <Link to="/">
          <img src={logo} alt="로고" />
        </Link>
      </div>
      <Container className="mb-5">
        <div className="text-center p-3">
          <h1>뉴질랜드 송금안내</h1>
          <h3 className="mt-3">- 카카오뱅크 해외송금 -</h3>
          <div className="text-center mt-5 bg-light p-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>1단계 : "해외송금 보내기" 메뉴이동</h3>
                <li>카카오뱅크 실행하기</li>
                <li>하단 "..." 메뉴 선택</li>
                <li>해외송금 보내기 실행</li>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer1} alt="송금 1단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>

          <div className="text-center mt-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>2단계 : "해외송금 보내기" 실행</h3>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer2} alt="송금 2단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
          <div className="text-center mt-3 bg-light  p-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>3단계 : 뉴질랜드 선택</h3>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer3} alt="송금 3단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
          <div className="text-center mt-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>4단계 : 송금액 입력</h3>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer4} alt="송금 4단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
          <div className="text-center mt-3 bg-light  p-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>5단계 : 수취인 정보 입력</h3>
                <li>수취인 : {Account.payee}</li>
                <li>수취인 주소 : {Account.address}</li>
                <li>수취인 도시 : AUCKLAND</li>
                <li>SWIFT 코드 : {Account.swift}</li>
                <li>은행명 : {Account.bank}</li>
                <li>계좌번호 : {Account.accountNo}</li>
                <br />
                <span className="text-danger">
                  * 계좌번호가 9자리이면 끝 2자리 앞에 숫자 {"0"}을 넣어서
                  10자리를 만들어서 입력합니다.
                </span>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer5} alt="송금 5단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
          <div className="text-center mt-3">
            <Row>
              <Col md={2}></Col>
              <Col className="text-start mt-3 p-3">
                <h3>6단계 : 해외송금 보내기</h3>
                <li>송금인 영문이름과 송금금액 확인</li>
              </Col>
              <Col className="mt-3">
                {" "}
                <img className="img-info" src={transfer6} alt="송금 6단계" />
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default TransferToNZ;
