import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { Cookies } from "react-cookie";
import PickupRegistered from "../../components/admin/pickup/PickupRegistered";
import PickupProcessing from "../../components/admin/pickup/PickupProcessing";
import PickupReady from "../../components/admin/pickup/PickupReady";
import PickupDone from "../../components/admin/pickup/PickupDone";
import PickupCancel from "../../components/admin/pickup/PickupCancel";

const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminOrderPickup() {
  const [activeTab, setActiveTab] = useState("픽업주문등록");

  return (
    <NormalLayout>
      <div>
        <h3>픽업주문</h3>
      </div>

      <div className="p-3"></div>
      <Nav tabs className="mb-3">
        {["픽업주문등록", "준비중", "제품준비완료", "픽업완료", "취소"].map(
          (tab) => (
            <NavItem key={tab}>
              <NavLink
                className={activeTab === tab ? "active" : ""}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          )
        )}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="픽업주문등록" className="border-0">
          <PickupRegistered isActive={activeTab === "픽업주문등록"} />
        </TabPane>
        <TabPane tabId="준비중" className="border-0">
          <PickupProcessing isActive={activeTab === "준비중"} />
        </TabPane>
        <TabPane tabId="제품준비완료" className="border-0">
          <PickupReady isActive={activeTab === "제품준비완료"} />
        </TabPane>
        <TabPane tabId="픽업완료" className="border-0">
          <PickupDone isActive={activeTab === "픽업완료"} />
        </TabPane>
        <TabPane tabId="취소" className="border-0">
          <PickupCancel isActive={activeTab === "취소"} />
        </TabPane>
      </TabContent>
    </NormalLayout>
  );
}

export default AdminOrderPickup;
