import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import PickupOrderForm from "../order/PickupOrderForm";
import Packing from "./Packing";

const cookies = new Cookies();

function PickupProcessing({ isActive }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState();
  const [showModifyForm, setShowModifyForm] = useState(false);
  const [showPackingForm, setShowPackingForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const showModifyFormToggle = () => setShowModifyForm(!showModifyForm);
  const showPackingFormToggle = () => setShowPackingForm(!showPackingForm);

  useEffect(() => {
    // 준비중 데이터 가져오기
    if (isActive) {
      getData();
    }
  }, [isActive]);

  const getData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/order?status=PROCESSING`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
      } else {
        setData(null);
        setFilteredOrders([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;

    resValue =
      "$" +
      parseFloat(unitPrice * qauntity)
        .toFixed(2)
        .toString();

    return resValue;
  };

  // 주문 확인처리
  const handleSave = () => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${selectedOrder.pickup_order_no} 주문건을 저장하시겠습니까?`
    );

    if (isConfirmed) {
      modifyOrder(selectedOrder);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("주문 수정을 취소했습니다.");
    }
  };

  const handleModify = async (o) => {
    setSelectedOrder(o);
    setShowModifyForm(true);
  };

  const modifyOrder = async (orderInfo) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/modify`,
        { orderInfo },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("수정완료");

        getData();
        showModifyFormToggle();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  // 주문 취소 처리
  const handleCancel = (order) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${order.pickup_order_no} 주문건 '취소' 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      cancelOrder(order);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("상태변경을 취소했습니다.");
    }
  };

  const cancelOrder = async (order) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup`,
        {
          order,
          before: "PROCESSING",
          after: "CANCEL",
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("취소 상태로 변경완료");
        getData();
      } else {
        console.error("취소 실패");
      }
    } catch (error) {
      console.error("취소 실패", error);
    }
  };

  const handlePacking = async (o) => {
    setSelectedOrder(o);
    setShowPackingForm(true);
  };

  // 주문 확인처리
  const handleConfirm = (order) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${order.pickup_order_no} 주문건 '제품준비완료' 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      confirmOrder(order);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("상태변경을 취소했습니다.");
    }
  };

  const confirmOrder = async (order) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup`,
        {
          order,
          before: "PROCESSING",
          after: "READY",
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("상태 변경완료");
        showPackingFormToggle();
        getData();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  return (
    <>
      {filteredOrders ? (
        <>
          <Modal
            isOpen={showModifyForm}
            toggle={showModifyFormToggle}
            className="custom-modal"
          >
            <ModalHeader toggle={showModifyFormToggle}>
              픽업주문 수정하기
            </ModalHeader>
            <ModalBody>
              <PickupOrderForm
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleSave()}>
                저장
              </Button>
              <Button color="secondary" onClick={showModifyFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={showPackingForm}
            toggle={showPackingFormToggle}
            className="custom-modal"
          >
            <ModalHeader toggle={showPackingFormToggle}>Packing</ModalHeader>
            <ModalBody>
              <Packing
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => handleConfirm(selectedOrder)}
              >
                패킹완료
              </Button>
              <Button color="secondary" onClick={showPackingFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">NO</th>
                  <th className="text-center p-2 border">주문일시</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">픽업주문번호</th>
                  <th className="text-center p-2 border">주문제품</th>
                  <th className="text-center p-2 border">메모</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={"7"}>
                      주문 없음
                    </td>
                  </tr>
                )}

                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {o.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center">{o.pickup_order_no}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td>{o.memo}</td>

                      <td className="text-center" style={{ width: "150px" }}>
                        <Button
                          color="success"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleModify(o)}
                          size="sm"
                        >
                          수정
                        </Button>{" "}
                        <Button
                          color="primary"
                          style={{ fontSize: "12px" }}
                          onClick={() => handlePacking(o)}
                          size="sm"
                        >
                          패킹
                        </Button>{" "}
                        <Button
                          color="danger"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleCancel(o)}
                          size="sm"
                        >
                          취소
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </>
  );
}

export default PickupProcessing;
