import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import PickupOrderForm from "../order/PickupOrderForm";
import AddPickupOrderForm from "../order/AddPickupOrderForm";

const cookies = new Cookies();

function PickupRegistered({ isActive }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState();
  const [showModifyForm, setShowModifyForm] = useState(false);
  const [showAddOrderForm, setShowAddOrderForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [addOrderInfo, setAddOrderInfo] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showModifyFormToggle = () => setShowModifyForm(!showModifyForm);
  const showAddOrderFormToggle = () => setShowAddOrderForm(!showAddOrderForm);

  useEffect(() => {
    // 픽업주문 데이터 가져오기
    if (isActive) {
      getData();
    }
  }, [isActive]);

  const getData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/order?status=REGISTERED`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
      } else {
        setData([]);
        setFilteredOrders([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 주문 일괄 확인처리
  const handleAllConfirm = () => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `주문건 모두 '준비중' 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      confirmAllOrder();
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("일괄상태변경을 취소했습니다.");
    }
  };

  const confirmAllOrder = async () => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/all`,
        "",
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("주문건 모두 '준비중' 상태로 변경완료");
        getData();
      } else {
        console.error("일괄상태 변경 실패");
      }
    } catch (error) {
      console.error("일괄상태 변경 실패", error);
    }
  };

  // 주문 확인처리
  const handleConfirm = (order) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${order.pickup_order_no} 주문건 '준비중' 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      confirmOrder(order);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("상태변경을 취소했습니다.");
    }
  };

  const confirmOrder = async (order) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup`,
        {
          order,
          before: "REGISTERED",
          after: "PROCESSING",
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("상태 변경완료");
        getData();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  // 주문 삭제하기
  const handleDelete = (no) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${no} 픽업주문을 삭제하시겠습니까?`);

    if (isConfirmed) {
      deleteOrder(no);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  const deleteOrder = async (pickupOrderIndex) => {
    try {
      const resList = await axios.delete(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup?pickupOrderIndex=${pickupOrderIndex}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("삭제 완료했습니다.");
        getData();
      } else {
        console.error("주문건을 삭제하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건을 삭제하는데 실패했습니다", error);
    }
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;

    resValue =
      "$" +
      parseFloat(unitPrice * qauntity)
        .toFixed(2)
        .toString();

    return resValue;
  };

  const handleModify = async (o) => {
    setSelectedOrder(o);
    setShowModifyForm(true);
  };

  // 주문 확인처리
  const handleSave = () => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${selectedOrder.pickup_order_no} 주문건을 저장하시겠습니까?`
    );

    if (isConfirmed) {
      modifyOrder(selectedOrder);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("주문 수정을 취소했습니다.");
    }
  };

  const modifyOrder = async (orderInfo) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/modify`,
        { orderInfo },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert("수정완료");

        getData();
        showModifyFormToggle();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  const addOrder = async () => {
    console.log("추가할 주문정보", addOrderInfo);

    // 이미 제출 중이면 추가 클릭 무시
    if (isSubmitting) return;

    setIsSubmitting(true);

    if (!addOrderInfo.wholesaler || addOrderInfo.products.length === 0) {
      alert("업체와 제품을 모두 선택하셔야 합니다.");
    } else {
      let orders = [];
      addOrderInfo.products.map((p) => {
        orders.push({
          qauntity: p.qauntity,
          unitPrice: p.price,
          productCode: p.product_code,
          productName: p.en_name,
        });
      });

      try {
        await axios
          .post(
            `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup`,
            {
              orders,
              wholesaler: addOrderInfo.wholesaler.wholesaler,
            },
            {
              headers: {
                Authorization: cookies.get("info").token,
              },
            }
          )
          .then((data) => {
            if (data.data.result === "success") {
              alert(
                `${addOrderInfo.wholesaler.wholesaler} 픽업주문 등록이 완료되었습니다.`
              );
            }
          })
          .catch((Error) => {
            console.log(Error);
          });
      } catch (error) {
        console.error("제출 중 오류 발생:", error);
        // 오류 처리 로직 (예: 사용자에게 오류 메시지 표시)
      } finally {
        setIsSubmitting(false);
        showAddOrderFormToggle();
        getData();
      }
    }
  };

  return (
    <>
      {filteredOrders ? (
        <>
          <Modal
            isOpen={showModifyForm}
            toggle={showModifyFormToggle}
            className="custom-modal"
          >
            <ModalHeader toggle={showModifyFormToggle}>
              픽업주문 수정하기
            </ModalHeader>
            <ModalBody>
              <PickupOrderForm
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleSave()}>
                저장
              </Button>
              <Button color="secondary" onClick={showModifyFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={showAddOrderForm}
            toggle={showAddOrderFormToggle}
            className="custom-modal"
          >
            <ModalHeader toggle={showAddOrderFormToggle}>
              업체 픽업주문 등록하기
            </ModalHeader>
            <ModalBody>
              <AddPickupOrderForm setAddOrderInfo={setAddOrderInfo} />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => addOrder()}
                disabled={
                  addOrderInfo.products && addOrderInfo.products.length === 0
                    ? true
                    : false
                }
              >
                {isSubmitting ? "처리 중..." : "주문등록"}
              </Button>
              <Button color="secondary" onClick={showAddOrderFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>
          <div className="border p-3">
            <div className="text-end mb-2">
              <Button color="success" onClick={() => setShowAddOrderForm(true)}>
                업체 픽업주문 등록
              </Button>{" "}
              <Button color="primary" onClick={() => handleAllConfirm()}>
                일괄 확인
              </Button>
            </div>
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">NO</th>
                  <th className="text-center p-2 border">주문일시</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">픽업주문번호</th>
                  <th className="text-center p-2 border">주문제품</th>
                  <th className="text-center p-2 border">메모</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={"7"}>
                      주문 없음
                    </td>
                  </tr>
                )}
                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {o.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center">{o.pickup_order_no}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td>{o.memo}</td>

                      <td className="text-center" style={{ width: "150px" }}>
                        <Button
                          color="success"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleModify(o)}
                          size="sm"
                        >
                          수정
                        </Button>{" "}
                        <Button
                          color="primary"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleConfirm(o)}
                          size="sm"
                        >
                          확인
                        </Button>{" "}
                        <Button
                          color="danger"
                          size="sm"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleDelete(o.pickup_order_no)}
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </>
  );
}

export default PickupRegistered;
