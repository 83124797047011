import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Table,
} from "reactstrap";
import { addCommasToNumber } from "../../../utils/AboutNumber";

import axios from "axios";
import { Cookies } from "react-cookie";

import ExcelOrderList from "../list/ExcelOrderList";
import PickupOrderList from "../list/PickupOrderList";

const cookies = new Cookies();

function InvoicePaid({ isActive, invoices }) {
  const [detailExcelOrder, setDetailExcelOrder] = useState([]);
  const [detailPickupOrder, setDetailPickupOrder] = useState([]);
  const [wholesaler, setWholesaler] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (isActive) {
      console.log(isActive, invoices);
      setDetailExcelOrder([]);
      setDetailPickupOrder([]);
    }
  }, [isActive]);

  const handleGetInvoiceDetail = async (
    selectedYear,
    selectedMonth,
    wholesaler
  ) => {
    setWholesaler(wholesaler);
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${selectedYear}/${selectedMonth}/${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      const res = resList.data;
      console.log("확인", res.data);
      if (res.result === "success") {
        setDetailExcelOrder(res.data.excelOrder);
        setDetailPickupOrder(res.data.pickupOrder);
      } else {
        // 상세 내역이 없을 때
        setDetailExcelOrder([]);
        setDetailPickupOrder([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <div>
      {invoices.length > 0 ? (
        <>
          <Table hover bordered className="mt-3" key={"table"}>
            <thead>
              <tr className="table-primary">
                <th className="text-center" key={"company"}>
                  업체명
                </th>
                <th className="text-center">년도</th>
                <th className="text-center">월</th>
                <th className="text-center">제품주문 가격</th>
                <th className="text-center">배송비</th>
                <th className="text-center">GST(15%)</th>
                <th className="text-center">총 정산금액</th>
                <th className="text-center">기능</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, idx) => {
                return (
                  <tr key={idx} className="text-center">
                    <th scope="row">{invoice.wholesaler}</th>
                    <td>{invoice.year}</td>
                    <td>{invoice.month}</td>
                    <td>
                      $
                      {addCommasToNumber(
                        parseFloat(
                          (
                            parseFloat(invoice.credit_payment) +
                            parseFloat(invoice.deposit_payment) +
                            parseFloat(invoice.point_payment) -
                            parseFloat(invoice.delivery_fee_amount)
                          ).toFixed(2)
                        )
                      )}
                    </td>
                    <td>${invoice.delivery_fee_amount}</td>
                    <td>
                      $
                      {(
                        parseFloat(invoice.credit_payment) +
                        parseFloat(invoice.deposit_payment) +
                        parseFloat(invoice.point_payment) -
                        parseFloat(invoice.delivery_fee_amount) -
                        (parseFloat(invoice.credit_payment) +
                          parseFloat(invoice.deposit_payment) +
                          parseFloat(invoice.point_payment) -
                          parseFloat(invoice.delivery_fee_amount)) /
                          1.15
                      ).toFixed(2)}
                    </td>
                    <td>${parseFloat(invoice.credit_payment).toFixed(2)}</td>
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          handleGetInvoiceDetail(
                            invoice.year,
                            invoice.month,
                            invoice.wholesaler
                          )
                        }
                      >
                        상세보기
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="border p-5 text-center">정산확인 대상 없음</div>
      )}
      {(detailExcelOrder.length > 0 || detailPickupOrder.length > 0) && (
        <>
          <ExcelOrderList
            wholesaler={wholesaler}
            detailExcelOrder={detailExcelOrder}
          />

          <PickupOrderList
            wholesaler={wholesaler}
            detailPickupOrder={detailPickupOrder}
          />
        </>
      )}
    </div>
  );
}

export default InvoicePaid;
