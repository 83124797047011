import React from "react";
import Moment from "react-moment";
import { Row, Col, Table } from "reactstrap";
import { paymentMethod } from "../../../utils/ConvertWord";
import { orderDetail } from "../../print/orderDetail";

function ExcelOrderList({ wholesaler, detailExcelOrder }) {
  return (
    <Row>
      <Col>
        <hr />
        <h4 className="my-5">{wholesaler} 상세 주문 내역</h4>

        <h5 className="my-3">{wholesaler} 엑셀주문 리스트(출고완료 기준)</h5>

        <Table hover bordered>
          <thead>
            <tr className="table-primary">
              <th className="text-center">번호</th>
              <th className="text-center">주문번호</th>
              <th className="text-center">등록일</th>
              <th className="text-center">출고일</th>
              <th className="text-center">이름</th>
              <th className="text-center">무게(kg)</th>
              <th className="text-center">배송료</th>
              <th className="text-center">총금액(제품+배송료)</th>
              <th className="text-center">결제방법</th>
              <th className="text-center">운송장번호</th>
            </tr>
          </thead>
          <tbody>
            {detailExcelOrder.map((order, idx) => (
              <tr key={idx}>
                <th scope="row" className="text-center">
                  {idx + 1}
                </th>
                <td>
                  <span
                    onClick={() => {
                      orderDetail(order.order_no);
                    }}
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    {order.order_no}
                  </span>
                </td>
                <td>
                  <Moment format="YYYY-MM-DD">{order.created_at}</Moment>
                </td>
                <td>
                  <Moment format="YYYY-MM-DD">{order.delivery_at}</Moment>
                </td>
                <td>{order.customer_name} </td>
                <td>{order.weight} </td>
                <td>${order.delivery_fee} </td>
                <td>${Math.abs(order.amount)}</td>
                <td>
                  {order.transactions.map((t, idx) => {
                    return (
                      <li key={idx}>
                        {paymentMethod(t.type)} : ${Math.abs(t.amount)}
                      </li>
                    );
                  })}
                </td>
                <td>
                  <a
                    href={`https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${order.hawb}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {order.hawb}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default ExcelOrderList;
