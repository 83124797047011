import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <Container className="error-page">
      <div>
        <h1>Oops! 경로를 확인해 주세요.</h1>
        <div className="p-3 text-center">
          <Link to="/login">로그인</Link>
        </div>
      </div>{" "}
    </Container>
  );
}

export default ErrorPage;
