import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import PickupCheck from "./PickupCheck";

const cookies = new Cookies();

function PickupReady({ isActive }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState();
  const [showPickupCheckingForm, setShowPickupCheckingForm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const showPickupCheckingFormToggle = () =>
    setShowPickupCheckingForm(!showPickupCheckingForm);

  useEffect(() => {
    // 픽업주문 데이터 가져오기
    if (isActive) {
      getData();
    }
  }, [isActive]);

  const getData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/order?status=READY`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
      } else {
        setData([]);
        setFilteredOrders([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;

    resValue =
      "$" +
      parseFloat(unitPrice * qauntity)
        .toFixed(2)
        .toString();

    return resValue;
  };

  // 주문 확인처리
  const handleCancel = (order) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${order.pickup_order_no} 주문건 '취소' 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      cancelOrder(order);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("상태변경을 취소했습니다.");
    }
  };

  const cancelOrder = async (order) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup`,
        {
          order,
          before: "READY",
          after: "CANCEL",
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert(
          `취소 처리 완료했습니다. 반드시 이카운트에서 ${order.pickup_order_no} 주문건을 삭제해 주세요.`
        );
        getData();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  // 테이블 내에 픽업확인 버튼 클릭시 모달창 띄우기
  const handleShowPickupCheck = async (o) => {
    setSelectedOrder(o);
    setShowPickupCheckingForm(true);
  };

  // 주문 확인처리
  const handleCheckPickup = (order) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${order.pickup_order_no} 주문건 픽업 완료 상태로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      checkPickup(order);
    } else {
      console.log("상태변경을 취소했습니다.");
    }
  };

  // 픽업확인 및 결제처리 하기
  const checkPickup = async (order) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/check`,
        {
          order,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.result === "success") {
        alert(`${order.pickup_order_no} 주문건 픽업완료`);
        showPickupCheckingFormToggle();
        getData();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  return (
    <>
      {filteredOrders ? (
        <>
          <Modal
            isOpen={showPickupCheckingForm}
            toggle={showPickupCheckingFormToggle}
            className="custom-modal"
          >
            <ModalHeader toggle={showPickupCheckingFormToggle}>
              픽업확인
            </ModalHeader>
            <ModalBody>
              <PickupCheck
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => handleCheckPickup(selectedOrder)}
              >
                픽업확인
              </Button>
              <Button color="secondary" onClick={showPickupCheckingFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">NO</th>
                  <th className="text-center p-2 border">주문일시</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">픽업주문번호</th>
                  <th className="text-center p-2 border">주문제품</th>
                  <th className="text-center p-2 border">메모</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={"7"}>
                      주문 없음
                    </td>
                  </tr>
                )}
                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {o.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center" style={{ width: "290px" }}>
                        {o.pickup_order_no}
                      </td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ width: "200px" }}>{o.memo}</td>

                      <td className="text-center" style={{ width: "150px" }}>
                        <Button
                          color="primary"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleShowPickupCheck(o)}
                          size="sm"
                        >
                          픽업확인
                        </Button>{" "}
                        <Button
                          color="danger"
                          style={{ fontSize: "12px" }}
                          onClick={() => handleCancel(o)}
                          size="sm"
                        >
                          취소
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </>
  );
}

export default PickupReady;
