import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Table, Button, Nav, NavItem, NavLink } from "reactstrap";
import Moment from "react-moment";
import axios from "axios";
import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../utils/AboutNumber";
import { handlePrint } from "../print/Invoice";

const cookies = new Cookies();

// 선불 충전금 내역 테이블 컴포넌트
const DepositHistoryTable = ({ data, activeTab }) => (
  <Table striped>
    <thead>
      <tr>
        <th>날짜</th>
        <th>내용</th>
        <th>선불 충전금</th>
        <th>메모</th>
        {activeTab === "적립" && <th>영수증</th>}
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((item, index) => (
          <tr key={index}>
            <td>
              {" "}
              <Moment format="YYYY-MM-DD">{item.created_at}</Moment>
            </td>

            <td>
              {item.deposit_index && `선불 충전`}
              {item.order_no && `엑셀주문(${item.order_no})`}
              {item.pickup_order_no && `픽업주문(${item.pickup_order_no})`}
            </td>
            <td className={item.amount >= 0 ? "text-success" : "text-danger"}>
              {item.amount >= 0 ? "+" : ""}
              {item.amount}
            </td>
            <td>{item.transaction_memo}</td>
            {activeTab === "적립" && (
              <td>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => handlePrint(item.amount, item.created_at)}
                >
                  영수증
                </Button>
              </td>
            )}
          </tr>
        ))}
    </tbody>
  </Table>
);

function DepositHistory() {
  const [activeTab, setActiveTab] = useState("적립");
  const [allDepositHistory, setAllDepositHistory] = useState([]);
  const [summary, setSummary] = useState({ deposit: 0, used: 0 });
  const [deposit, setDeposit] = useState(0);

  useEffect(() => {
    // 포인트 리스트 가져오기
    getDepositHistory();
  }, []);

  const getDepositHistory = async () => {
    try {
      const encodedWholesaler = encodeURIComponent(cookies.get("info").profile);

      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/deposit/history?wholesaler=${encodedWholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      setAllDepositHistory(res.data.data.history);
      setSummary(res.data.data.summary);
      setDeposit(res.data.data.deposit.deposit_balance);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 포인트 유형에 따라 필터링된 데이터
  const filteredDepositHistory = useMemo(() => {
    switch (activeTab) {
      case "적립":
        return allDepositHistory.filter((item) => item.amount > 0);
      case "사용":
        return allDepositHistory.filter((item) => item.amount < 0);
      default:
        return allDepositHistory;
    }
  }, [activeTab, allDepositHistory]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            선불 충전금 :${deposit}
          </div>
        </Col>
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            총 적립금액 : ${addCommasToNumber(summary.deposit)}
            <br />총 사용금액 :${addCommasToNumber(summary.used)}
          </div>
        </Col>
      </Row>
      <Nav tabs className="mb-3 bg-light">
        {["적립", "사용"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <DepositHistoryTable
        data={filteredDepositHistory}
        activeTab={activeTab}
      />
    </>
  );
}

export default DepositHistory;
