import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

function AddProduct({ newProducts, setNewProducts, wholesaler }) {
  // 도매업체 정보 가져오기
  // 제품 상세정보와 가격 정보 가져오기

  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [newProductCodes, setNewProductCodes] = useState(
    newProducts.map((item) => item.product_code)
  );
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [memo, setMemo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const performSearch = async (e) => {
    e.preventDefault();

    if (keyword === "") {
      setSearchResults([]);
      alert("검색어를 입력해 주세요. ");
      return;
    }

    // 검색
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/search/product?keyword=${keyword}&wholesaler=${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.status === 200) {
        setSearchResults(resList.data);
        if (resList.data.length === 0) {
          alert(`입력하신 '${keyword}'에 대한 제품은 없습니다.`);
        }
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleqauntityChange = (code, qauntity) => {
    if (qauntity <= 0) {
      alert("최소수량은 1개 이상입니다.");
      qauntity = 1;
    }
    setNewProducts(
      newProducts.map((p) => (p.product_code === code ? { ...p, qauntity } : p))
    );
  };

  const handleDeleteProduct = (code) => {
    setNewProducts(newProducts.filter((p) => p.product_code !== code));
    setNewProductCodes(newProductCodes.filter((item) => item !== code));
  };

  const handleProductSelect = (product) => {
    // setSelectedProducts([...selectedProducts, { ...product, qauntity: 1 }]);
    setNewProducts([...newProducts, { ...product, qauntity: 1 }]);
    setNewProductCodes([...newProductCodes, product.product_code]);
  };

  const handleReset = () => {
    setKeyword("");
    setSearchResults([]);
  };

  const handleKeyPressSearch = (e) => {
    if (e.charCode === 13) {
      performSearch();
    }
  };

  return (
    <div>
      <div className="my-4 p-3 border rounded bg-light">
        <Form onSubmit={performSearch}>
          <FormGroup>
            <Label for="keyword">제품코드 또는 제품명으로 검색하세요.</Label>
            <InputGroup className="text-center" style={{ width: "500px" }}>
              <Input
                type="text"
                id="keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="검색어를 입력해주세요."
              />
              <Button type="submit" color="primary">
                검색
              </Button>{" "}
              <Button
                style={{ marginLeft: "10px" }}
                color="warning"
                onClick={() => handleReset()}
              >
                검색 초기화
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
        {searchResults.length > 0 && (
          <>
            <Row>
              <Col>
                <h4 className="">"{keyword}" 검색 결과</h4>
              </Col>
            </Row>
            <Row className="mb-4 p-2">
              {searchResults.length > 0 && (
                <Table striped bordered hover className="mt-3 p-2">
                  <thead>
                    <tr className="table-primary">
                      <th className="text-center">담기</th>
                      <th className="text-center">제품코드</th>
                      <th className="text-center">제품명</th>
                      <th className="text-center">공급가</th>
                      {/* <th className="text-center">특이사항</th> */}
                      <th className="text-center">재고상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((product, idx) => (
                      <tr key={product.product_code}>
                        <td className="text-center">
                          {/* {product.stock_status !== "empty" && (
                           
                          )} */}
                          {/* <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleProductSelect(product)}
                          >
                            담기
                          </Button> */}
                          {newProductCodes.includes(product.product_code) ? (
                            <Button
                              color="warning"
                              size="sm"
                              onClick={() =>
                                handleDeleteProduct(product.product_code)
                              }
                            >
                              완료
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => handleProductSelect(product)}
                            >
                              담기
                            </Button>
                          )}
                        </td>
                        <td className="text-center">{product.product_code}</td>
                        <td style={{ textAlign: "left" }}>
                          {product.en_name}
                          {product.point === "Y" && (
                            <span className="text-danger bold_800">[P]</span>
                          )}
                        </td>
                        <td className="text-center" style={{ width: "70px" }}>
                          {product.price}
                        </td>
                        {/* <td className="text-center">
                          {product.product_status === "판매중"
                            ? ""
                            : product.product_status}{" "}
                        </td> */}
                        <td
                          className="text-center"
                          style={{
                            width: "80px",
                            backgroundColor:
                              product.stock_status === "empty"
                                ? "lightgrey"
                                : product.stock_status,
                          }}
                        >
                          {product.stock_status === "empty" && "0"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Row>
          </>
        )}
      </div>

      <div className="border p-2 mt-3 bg-light">
        <h4 className="mb-3 mt-2">추가 주문 제품</h4>
        <Table>
          <thead>
            <tr>
              <th>제품명</th>
              <th>가격</th>
              <th>수량</th>
              <th>소계</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {newProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.en_name}</td>
                <td>${product.price}</td>
                <td>
                  <Input
                    type="number"
                    value={product.qauntity}
                    onChange={(e) =>
                      handleqauntityChange(
                        product.product_code,
                        parseInt(e.target.value)
                      )
                    }
                    min={1}
                    step={1}
                    style={{ width: "70px" }}
                  />
                </td>
                <td>${(product.price * product.qauntity).toFixed(2)}</td>
                <td>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleDeleteProduct(product.product_code)}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default AddProduct;
