import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <>
      <div>
        <section className="bg-dark">
          <Container className="mt-1">
            <Row className="p-3">
              <Col></Col>
            </Row>
            {/* <Row>
							<Col>
								<p>About Us</p>
								<Link to="#">메가몰소개</Link>
							</Col>
							<Col>
								<p>Services</p>
								<Link to="/policy/service">이용약관</Link>
							</Col>
							<Col>
								<p>Contact Us</p>
								<Link to="#">연락처</Link>
							</Col>
							<Col>
								<p>Contact Us</p>
								<Link to="#">선불 충전금 활용 안내</Link>
							</Col>
						</Row>
						<Row className="text-center mt-5">
							<Col className="copyright">
								Copyright Megamall. All rights Reserved.
							</Col>
						</Row> */}
            <Row className="text-light">
              <Col md="12" className="mt-3" style={{ wordBreak: "keep-all" }}>
                메가몰은 대한민국 도매업체를 위한 뉴질랜드 온라인 쇼핑몰입니다.
                <br />
                뉴질랜드 및 전세계로 EMS배송을 실시하며 해당법률지역은
                뉴질랜드에 속합니다.
                <br />
                메가몰에서 판매되는 건강기능식품은 뉴질랜드 식약청에 등록된
                건강기능식품이지만 대한민국 식약청에는 건강기능식품으로 등록되지
                않은 제품이 포함되어 있습니다.
                {/* <br />
                메가몰에 모든 이미지와 콘텐츠는 저작권 보호를 받고 있습니다.{" "}
                <br />
                불법복제 및 무단 도용시 민,형사상의 처벌을 받을 수 있습니다.
                <br /> */}
              </Col>
              <Col md="4" className="text-center">
                {/* <p>
									[서비스]
									<br />
									<Link to="#">엑셀대량주문 사용안내</Link>
								</p> */}

                {/* <p className="">
                  [정책]
                  <br />
                  <Link
                    to="/policy/service"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    이용약관
                  </Link>
                </p> */}
              </Col>
              {/* <Col md="4" className="mt-3">
                <p>[고객센터]</p>
                회사명 : HI
                <br />
                주소 : Albany Auckland New Zealand
                <br />
              </Col> */}
            </Row>
            <Row className="text-center mt-5 text-light">
              <Col className="copyright mb-5">
                COPYRIGHT ⓒ Megamall Rights Reserved.
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Footer;
