import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { addCommasToNumber } from "../../../utils/AboutNumber";

import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";

const cookies = new Cookies();

function InvoicePending({ isActive, invoices }) {
  const [detailInfo, setDetailInfo] = useState([]);
  const [wholesaler, setWholesaler] = useState("");

  useEffect(() => {
    if (isActive) {
      console.log(isActive, invoices);
      setDetailInfo([]);
    }
  }, [isActive]);

  const handleGetInvoiceDetail = async (
    selectedYear,
    selectedMonth,
    wholesaler
  ) => {
    setWholesaler(wholesaler);
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${selectedYear}/${selectedMonth}/${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      const res = resList.data;
      console.log("확인", res.data.list);
      if (res.result === "success") {
        setDetailInfo(res.data.list);
      } else {
        // alert("인보이스 상세정보 건이 없습니다.");
        setDetailInfo([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <div>
      {invoices.length > 0 && (
        <Table hover bordered className="mt-3" key={"table"}>
          <thead>
            <tr className="table-primary">
              <th className="text-center" key={"company"}>
                업체명
              </th>
              <th className="text-center">년도</th>
              <th className="text-center">월</th>
              <th className="text-center">제품주문 가격(GST included)</th>
              <th className="text-center">배송비</th>
              <th className="text-center">GST(15%)</th>
              <th className="text-center">총 정산금액</th>
              <th className="text-center">기능</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, idx) => {
              return (
                <tr key={idx} className="text-center">
                  <th scope="row">{invoice.wholesaler}</th>
                  <td>{invoice.year}</td>
                  <td>{invoice.month}</td>
                  <td>${invoice.order_amount - invoice.delivery_fee_amount}</td>
                  <td>${invoice.delivery_fee_amount}</td>
                  <td>
                    $
                    {(
                      invoice.order_amount -
                      invoice.delivery_fee_amount -
                      (invoice.order_amount - invoice.delivery_fee_amount) /
                        1.15
                    ).toFixed(2)}
                  </td>
                  <td>${parseFloat(invoice.order_amount).toFixed(2)}</td>
                  <td>
                    <Button size="sm" color="primary">
                      발행
                    </Button>{" "}
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() =>
                        handleGetInvoiceDetail(
                          invoice.year,
                          invoice.month,
                          invoice.wholesaler
                        )
                      }
                    >
                      상세보기
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {detailInfo.length > 0 && (
        <>
          <Row>
            <Col>
              <h5 className="my-3">{wholesaler} 주문 리스트(출고완료 기준)</h5>
              <Table hover bordered>
                <thead>
                  <tr className="table-primary">
                    <th className="text-center">번호</th>
                    <th className="text-center">주문번호</th>
                    <th className="text-center">등록일</th>
                    <th className="text-center">출고일</th>
                    <th className="text-center">이름</th>
                    <th className="text-center">무게(kg)</th>
                    <th className="text-center">배송료</th>
                    <th className="text-center">총금액(제품+배송료)</th>
                    <th className="text-center">운송장번호</th>
                    <th className="text-center">기능</th>
                  </tr>
                </thead>
                <tbody>
                  {detailInfo.map((order, idx) => (
                    <tr key={idx}>
                      <th scope="row" className="text-center">
                        {idx + 1}
                      </th>
                      <td>{order.order_no}</td>
                      <td>
                        <Moment format="YYYY-MM-DD">{order.created_at}</Moment>
                      </td>
                      <td>
                        <Moment format="YYYY-MM-DD">{order.delivery_at}</Moment>
                      </td>
                      <td>{order.customer_name} </td>
                      <td>{order.weight} </td>
                      <td>${order.delivery_fee} </td>
                      <td>${Math.abs(order.amount)}</td>
                      <td>{order.hawb} </td>
                      <td>수정 </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default InvoicePending;
