import React from "react";
import NoSidebarLayout from "../components/layout/NoSidebarLayout";

function Pending() {
  return (
    <NoSidebarLayout>
      <div>관리자 승인 전 대기 페이지</div>
    </NoSidebarLayout>
  );
}

export default Pending;
