import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import { Row, Col } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import IntegrationSearchForm from "../../components/search/IntegrationSearchForm";
import axios from "axios";
import { Cookies } from "react-cookie";
import ExcelOrderList from "../list/ExcelOrderList";
import PickupOrderList from "../list/PickupOrderList";

const cookies = new Cookies();

function Search() {
  let { keyword } = useParams();
  const [orders, setOrders] = useState();
  const [excelOrders, setExcelOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState([]);

  useEffect(() => {
    if (keyword) {
      const getData = async () => {
        try {
          const resList = await axios.get(
            `${process.env.REACT_APP_WHOLESALE_API_URL}/search/keyword?keyword=${keyword}`,
            {
              headers: {
                Authorization: cookies.get("info").token,
              },
            }
          );
          console.log(resList.data.data.excelOrder);
          const tempExcelOrders = resList.data.data.excelOrder;
          const tempPickupOrders = resList.data.data.pickupOrder;
          setExcelOrders(tempExcelOrders);
          setPickupOrders(tempPickupOrders);
        } catch (error) {
          console.error("데이터를 불러오는데 실패했습니다", error);
        }
      };
      getData();
    }
  }, []);

  return (
    <NormalLayout>
      <Row className="my-2">
        <Col>
          <h3 className="">통합 검색</h3>
        </Col>
      </Row>

      {/* 통합 검색 폼 */}
      <IntegrationSearchForm setOrders={setOrders} />

      {keyword && <>{`'${keyword}' 검색 결과`}</>}

      {/* 결과 */}
      {/* 주문정보 상세보기 */}
      <ExcelOrderList excelOrders={excelOrders} />

      <PickupOrderList pickupOrders={pickupOrders} />
    </NormalLayout>
  );
}

export default Search;
