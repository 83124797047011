import * as XLSX from "xlsx";
import { convertYYYYMD } from "./Aboutdate";

let currentDate = convertYYYYMD(new Date());
const currentYYYYMD =
  currentDate.year + "-" + currentDate.month + "-" + currentDate.day;
const currentMD = currentDate.month + "-" + currentDate.day;

export const genExcelForDoneDownloadFunc = (orderList) => {
  console.log("orderlist is", orderList);
  let orderArr = [];
  orderList.map((order, idx) => {
    order.order_product.map((item, idx2) => {
      orderArr.push([
        idx2 === 0 ? idx + 1 : null,
        idx2 === 0 ? `${order.wholesaler}` : null,
        idx2 === 0 ? `${currentYYYYMD}` : null,
        idx2 === 0 ? `${currentMD}` : null,
        idx2 === 0 ? "-" : null,
        null,
        idx2 === 0 ? (order.hawb === null ? null : `${order.hawb}`) : null,
        order.wholesaler,
        idx2 === 0 ? `${order.order_no}` : null,
        idx2 === 0
          ? order.shipper_memo === null
            ? null
            : `${order.shipper_memo}`
          : null,
        idx2 === 0 ? `${order.customer_name}` : null,
        idx2 === 0 ? `${order.pcc}` : null,
        idx2 === 0 ? `${order.customer_tel}` : null,
        idx2 === 0 ? `${order.customer_mobile}` : null,
        idx2 === 0 ? `${order.zip}` : null,
        idx2 === 0 ? `${order.address}` : null,
        idx2 === 0
          ? order.delivery_memo === null
            ? null
            : `${order.delivery_memo}`
          : null,
        item.productCode,
        item.productName,
        item.qauntity,
        idx2 === 0 ? `${order.weight}` : null,
        null,
        !isNaN(item.unitPrice) && item.unitPrice * item.qauntity,
        idx2 === 0
          ? order.delivery_fee === null
            ? null
            : `${order.delivery_fee}`
          : null,
        null,
        null,
        null,
        item.productName,
        item.unitPrice,
        idx2 === 0 ? `${order.wholesaler_order_no}` : null,
        null,
        null,
      ]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet([
    [
      "번호",
      "회사코드",
      "배송일",
      "발주일",
      "보낸이",
      "추가서류",
      "HAWB",
      "마켓코드",
      "주문번호",
      "확인",
      "이름",
      "통관부호",
      "전화번호",
      "모바일번호",
      "우편번호",
      "주소",
      "배송메모",
      "재고관리코드",
      "기초상품명",
      "수량",
      "무게",
      "사은품",
      "금액",
      "배송비",
      "온라인상품명",
      "옵션",
      "HSCODE",
      "영문상품명",
      "단가",
      "쇼핑몰주문번호",
      "결제완료일",
      "주문수집일",
    ],
    ...orderArr.map((order) => order), // 두 번째 행부터 데이터
  ]);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "uploadform");

  // 파일 생성 및 내보내기
  let downloadDate = new Date();
  XLSX.writeFile(wb, `주문등록목록_${downloadDate}.xlsx`);
};
