import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import axios from "axios";
import { orderStatus } from "../../utils/ConvertWord";
import { convertPriceFormat } from "../../utils/AboutNumber";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function OrderDetailsModal({ isOpen, toggle, order }) {
  const [payInfo, setPayInfo] = useState({
    deposit: 0.0,
    point: 0.0,
    invoice: 0.0,
  });
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (order) {
      //DB에서 결제 정보 가져오기
      getPayInfo();

      // 결제총액 가져오기
      let tempAmount = 0.0;
      order.order_product.map((i) => {
        tempAmount += i.qauntity * parseFloat(i.unitPrice);
      });
      tempAmount += parseFloat(order.delivery_fee ?? 0.0);
      setAmount(tempAmount.toFixed(2));
    }
  }, [order]);

  const getPayInfo = async () => {
    // 결제 정보 가져오기
    if (order) {
      const resPayInfo = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/transaction/order?orderno=${order.order_no}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      console.log(resPayInfo);
      if (resPayInfo.data.result === "success") {
        setPayInfo(resPayInfo.data.data);
      }
    }
  };

  if (!order) return null;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>주문 상세보기</ModalHeader>
      <ModalBody>
        <div className="p-2 mb-3 border rounded">
          <li>
            <strong>업체명 : </strong>
            {order.wholesaler}
          </li>
          <li>
            <strong>주문번호 : </strong>
            {order.order_no}
          </li>
          <li>
            <strong>주문일 : </strong>
            <Moment format="YYYY-MM-DD HH:MM">{order.created_at}</Moment>
          </li>
          <li>
            <strong>갱신일 : </strong>
            <Moment format="YYYY-MM-DD HH:MM">{order.delivery_at}</Moment>
          </li>

          <li>
            <strong>주문상태 : </strong>
            {orderStatus(order.order_status)}
            {order.order_status === "DONE" && (
              <span> (운송장번호 : {order.hawb})</span>
            )}
          </li>
        </div>
        <div className="p-2 mb-3 border rounded">
          <h5>주문제품</h5>
          <Table hover bordered style={{ margin: "0px", width: "100%" }}>
            <thead>
              <tr>
                <th className="text-center p-1">제품코드</th>
                <th className="text-center p-1">제품명</th>
                <th className="text-center p-1">수량</th>
                <th className="text-center p-1">단가</th>
                <th className="text-center p-1">총액</th>
              </tr>
            </thead>
            <tbody>
              {order.order_product.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center" style={{ width: "70px" }}>
                      {i.productCode}
                    </td>

                    <td className="">{i.productName}</td>
                    <td style={{ width: "60px" }} className="text-center">
                      {i.qauntity}개
                    </td>
                    <td style={{ width: "70px" }} className="text-center">
                      ${i.unitPrice}
                    </td>

                    <td style={{ width: "70px" }} className="text-center">
                      {convertPriceFormat(i.unitPrice, i.qauntity)}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td className="text-center" colSpan={"4"}>
                  배송비
                </td>

                <td style={{ width: "70px" }} className="text-center">
                  {order.delivery_fee ? <>${order.delivery_fee}</> : "없음"}
                </td>
              </tr>
              <tr>
                <td className="text-center" colSpan={"4"}>
                  총 결제금액
                </td>

                <td style={{ width: "70px" }} className="text-center bold_800">
                  ${amount}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="p-2 mb-3 border rounded">
          <h5>결제정보</h5>
          {payInfo.deposit && (
            <li>선불충전금 : ${Math.abs(parseFloat(payInfo.deposit))}</li>
          )}
          {payInfo.point && (
            <li>포인트 : ${Math.abs(parseFloat(payInfo.point))}</li>
          )}
          {payInfo.invoice && (
            <li>인보이스 : ${Math.abs(parseFloat(payInfo.invoice))}</li>
          )}
        </div>
        <div className="p-2 mb-3 border rounded">
          <h5>주문상태이력</h5>

          <Table hover bordered>
            <thead>
              <tr>
                <th className="text-center p-1">상태변경일</th>
                <th className="text-center p-1">이전</th>
                <th className="text-center p-1">이후</th>
              </tr>
            </thead>
            <tbody>
              {order.status_history.status.map((s) => {
                return (
                  <tr>
                    <td className="text-center" style={{ width: "110px" }}>
                      <Moment format="YYYY-MM-DD">{s.updated_at}</Moment>
                    </td>
                    <td className="text-center">{orderStatus(s.before)}</td>
                    <td className="text-center">{orderStatus(s.after)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default OrderDetailsModal;
