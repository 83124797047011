import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import NormalLayout from "../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { AuthContext } from "../context/AuthContext";
import { Cookies } from "react-cookie";
import imgOrderStatus from "../assets/img/img_order_status.png";
import { addCommasToNumber } from "../utils/AboutNumber";
import MainSearchForm from "../components/search/MainSearchForm";

const cookies = new Cookies();

function Main() {
  const { user } = useContext(AuthContext);
  const [products, setProducts] = useState(null);
  const [balance, setBalance] = useState({
    deposit: 0.0,
    point: 0.0,
    credit: 0.0,
  });

  const [status, setStatus] = useState({
    REGISTERED: 0,
    PROCESSING: 0,
    DONE: 0,
    CANCEL: 0,
  });

  const [pickupStatus, setPickupStatus] = useState({
    REGISTERED: 0,
    PROCESSING: 0,
    READY: 0,
    DONE: 0,
    CANCEL: 0,
  });

  useEffect(() => {
    const orderStauts = async () => {
      try {
        // 엑셀 주문상태별 주문수
        const encodedWholesaler = encodeURIComponent(
          cookies.get("info").profile
        );
        const resList = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/order/status?wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        // 1건 이상의 주문수가 있을 때만 출력
        if (resList.data.result === "success") {
          let tempStatusArr = resList.data.data.status;
          let statusObj = {};
          tempStatusArr.map((s) => [(statusObj[s.order_status] = s.count)]);
          setStatus(statusObj);
        }

        // 픽업 주문상태별 주문수
        const pickupStatus = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/order/pickup_status?wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        console.log(pickupStatus);

        // 1건 이상의 주문수가 있을 때만 출력
        if (pickupStatus.data.result === "success") {
          let tempStatusArr = pickupStatus.data.data.status;
          let statusObj = {};
          tempStatusArr.map((s) => [(statusObj[s.pickup_status] = s.count)]);
          setPickupStatus(statusObj);
        }

        // 스페셜 할인제품 정보 가져오기
        const resProduct = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/specialproduct`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        // 1건 이상의 주문수가 있을 때만 출력
        if (resProduct.data.result === "success") {
          setProducts(resProduct.data.data);
        }

        // 도매업체 정보 가져오기
        const resCompany = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/company?wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        console.log("rescompany is ", resCompany);

        // cookie 정보 업데이트
        const companyInfo = cookies.get("info");
        companyInfo.t = resCompany.data.data.gst;
        companyInfo.c = resCompany.data.data.credit_purchase_enabled;
        cookies.set("info", companyInfo);

        // Asset 정보 설정
        if (resCompany.data.result === "success") {
          cookies.set("balance", {
            deposit: resCompany.data.data.deposit,
            point: resCompany.data.data.point,
            credit: resCompany.data.data.credit,
          });

          setBalance({
            deposit: resCompany.data.data.deposit,
            point: resCompany.data.data.point,
            credit: resCompany.data.data.credit,
          });
        }
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
      }
    };

    orderStauts();
  }, []);

  return (
    <NormalLayout>
      <div className="row">
        <div className="col-md-6">
          <div>
            <h5>페이</h5>
          </div>
          <div className="mt-3 mb-5">
            <Card style={{ height: "105px" }}>
              <CardBody className="text-start no-underline">
                <Row>
                  <Col>
                    <Table borderless>
                      <tbody>
                        <tr>
                          <td style={{ width: "90px" }}>
                            <Link
                              to="/pay/deposit"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              선불충전금
                            </Link>
                          </td>
                          <td>
                            <Link
                              to="/pay/deposit"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              ${addCommasToNumber(balance.deposit)}{" "}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link
                              to="/pay/point"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              포인트
                            </Link>
                          </td>
                          <td>
                            <Link
                              to="/pay/point"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              ${addCommasToNumber(balance.point)}{" "}
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Table borderless>
                      <tbody>
                        <tr>
                          <td style={{ width: "90px" }}>
                            <Link
                              to="/pay/invoice"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              여신
                            </Link>
                          </td>
                          <td>
                            <Link
                              to="/pay/invoice"
                              style={{
                                textDecoration: "none",
                                fontSize: "16px",
                              }}
                            >
                              {parseFloat(balance.credit) >= 0 ? (
                                <>${balance.credit}</>
                              ) : (
                                <span className="text-danger">
                                  -$
                                  {addCommasToNumber(Math.abs(balance.credit))}
                                </span>
                              )}
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h5>통합검색</h5>
          </div>
          <div className="mt-3 mb-5 text-center border p-3">
            <MainSearchForm />
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h5>엑셀 주문현황</h5>
          </div>
          <div className="mt-3 mb-5">
            <div className="row">
              <div className="col-md-3">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">주문등록 완료</CardTitle>
                    <CardText>
                      <Link
                        to="/manage/registered"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {status.REGISTERED ? status.REGISTERED : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">주문 처리 중</CardTitle>
                    <CardText>
                      <Link
                        to="/manage/processing"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {status.PROCESSING ? status.PROCESSING : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">발송완료</CardTitle>
                    <CardText>
                      <Link
                        to="/manage/done"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {status.DONE ? status.DONE : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-3">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h6">주문취소</CardTitle>
                    <CardText>
                      <Link
                        to="/manage/cancel"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {status.CANCEL ? status.CANCEL : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h5>픽업 주문현황</h5>
          </div>
          <div className="mt-3 mb-5">
            <div className="row">
              <div className="col-md-3">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">픽업주문등록</CardTitle>
                    <CardText>
                      <Link
                        to="/pickuplist"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {pickupStatus.REGISTERED ? pickupStatus.REGISTERED : 0}
                        건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-2">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">준비 중</CardTitle>
                    <CardText>
                      <Link
                        to="/pickuplist"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {pickupStatus.PROCESSING ? pickupStatus.PROCESSING : 0}
                        건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">제품준비완료</CardTitle>
                    <CardText>
                      <Link
                        to="/pickuplist"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {pickupStatus.READY ? pickupStatus.READY : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-2">
                <Card className=" step-arrow">
                  <CardBody className="text-center">
                    <CardTitle tag="h6">완료</CardTitle>
                    <CardText>
                      <Link
                        to="/pickuplist"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {pickupStatus.DONE ? pickupStatus.DONE : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-md-2">
                <Card>
                  <CardBody className="text-center">
                    <CardTitle tag="h6">취소</CardTitle>
                    <CardText>
                      <Link
                        to="/pickuplist"
                        className="fontsize_16rem"
                        style={{ textDecoration: "none" }}
                      >
                        {pickupStatus.CANCEL ? pickupStatus.CANCEL : 0}건
                      </Link>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col md={6}>
          <h5>메뉴 안내</h5>
          <div className="border p-3">
            <Table hover>
              <tbody>
                <tr>
                  <td className="p-2" style={{ width: "90px" }}>
                    <li className="bold_600">주문서</li>
                  </td>
                  <td className="p-2 text-start">
                    '주문하기' 메뉴에서 엑셀파일로 주문하고, '주문목록'에서
                    주문내용을 상세히 확인할 수 있습니다.
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <li className="bold_600">주문관리</li>
                  </td>
                  <td className="p-2 text-start">
                    주문상태별로 주문건을 확인할 수 있습니다.
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <li className="bold_600">제품</li>
                  </td>
                  <td className="p-2 text-start">
                    제품공급가, 소매판매가 그리고 재고상태까지 제품정보를 확인할
                    수 있습니다.
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <h5 className="mt-5">엑셀 주문상태 안내</h5>
          <div className="border p-3">
            <img
              src={imgOrderStatus}
              style={{ width: "100%" }}
              alt="주문상태"
            />
            <Table hover className="mt-3">
              <tbody>
                <tr>
                  <td className="p-2" style={{ width: "120px" }}>
                    <li className="bold_600">주문등록 완료</li>
                  </td>
                  <td className="p-2 text-start">
                    주문서를 등록한 상태이며, 주문 삭제가 가능합니다.
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    {" "}
                    <li className="bold_600">주문 처리 중</li>
                  </td>
                  <td className="p-2 text-start">
                    등록된 주문서를 기준으로 출고준비를 하는 단계입니다.
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    {" "}
                    <li className="bold_600">발송완료</li>
                  </td>
                  <td className="p-2 text-start">
                    출고완료된 상태로, 주문건별로 무게, 배송비, 운송장번호를
                    확인할 수 있습니다.
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    {" "}
                    <li className="bold_600">주문취소</li>
                  </td>
                  <td className="p-2 text-start">
                    고객의 요구나 다양한 이슈로 주문이 취소된 상태입니다.
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={6}>
          <h5>스페셜 할인제품</h5>
          <Table bordered striped hover>
            <thead>
              <tr className="table-primary">
                <th className="text-center" style={{ width: "80px" }}>
                  제품코드
                </th>
                <th className="text-center" style={{ width: "300px" }}>
                  상품명
                </th>
                <th className="text-center" style={{ width: "80px" }}>
                  특별가격
                </th>
                <th className="text-center cell">비고</th>
              </tr>
            </thead>
            <tbody>
              {products ? (
                products.map((p, idx) => {
                  return (
                    <tr key={p.product_code}>
                      <td className="text-center">{p.product_code}</td>
                      <td className="text-start cell-300">{p.en_name}</td>
                      <td className="text-center">{p.price}</td>
                      <td className="text-center cell">{p.note}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    할인제품 없음
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </NormalLayout>
  );
}

export default Main;
