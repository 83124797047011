// 해당 제품의 이미지 확인하는 팝업

export const showProductImages = async (code) => {
  const printWindow = window.open("", "_blank", "width=700, height=800");

  printWindow.document.write(`
<!DOCTYPE html>
<html lang="ko">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>주문 상세 정보</title>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/css/bootstrap.min.css" rel="stylesheet">
    <style>
        body { padding: 20px; font-family: 'Noto Sans KR', sans-serif;}
        .badge { font-size: 100%; font-family: 'Noto Sans KR', sans-serif; }
    </style>
</head>
<body>
<div class="container">
        <h1 id="orderTitle" class="mb-4">이미지뱅크 (제품코드 : ${code.toUpperCase()})</h1>

        ${[2, 3, 4, 5, 6, 7, 8, 9]
          .map(
            (fn) =>
              `<img
              src=${
                process.env.REACT_APP_PRODUCT_IMAGE_URL
              }/${code.toLowerCase()}/${fn}.jpg 
            onerror="this.style.display='none'"
              alt="제품이미지"
              width="100%" 
              class="border rounded"
            />`
          )
          .join("")}


        ${[90, 91, 92, 93, 94]
          .map(
            (fn) =>
              `<img src=${
                process.env.REACT_APP_PRODUCT_IMAGE_URL
              }/${code.toLowerCase()}/${fn}.jpg 
            onerror="this.style.display='none'"
                          alt="제품이미지"
                         width="100%" 
                          class="border rounded"
                         
                        />`
          )
          .join("")}

    </div>


</body>
</html>
`);
};
