import moment from "moment";
import { Cookies } from "react-cookie";
import info from "../../assets/info/packingSlipInfo.json";
import axios from "axios";

const cookies = new Cookies();

export const handlePrintPackingSlip = async (orderData) => {
  let amountQTY = 0;
  orderData.order_product.map((item) => {
    amountQTY += parseInt(item.qauntity);
  });

  // 업체 정보 가져오기
  let company = null;
  try {
    const getCompany = await axios.get(
      `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company/${orderData.wholesaler}`,
      {
        headers: {
          Authorization: cookies.get("info").token,
        },
      }
    );
    company = getCompany.data;
  } catch (error) {
    console.error("데이터를 불러오는데 실패했습니다", error);
  }

  const printWindow = window.open("", "_blank", "width=800,height=900");

  printWindow.document.write(`
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>PACKING SLIP</title>
    <style>
     @media print {
            body {
                width: 210mm;
                height: 297mm;
                margin: 0;
                padding: 0;
                font-size: small; /* 인쇄 시 폰트 크기를 12pt로 설정 */
                line-height: 1.2; /* 줄 간격을 1.5배로 설정 */
            }

            .packing-slip {
                width: 100%;
                height: 100%;
                padding: 20mm;
                box-sizing: border-box;
            }

            .header {
                margin-bottom: 10mm;
            }

            .info {
                margin-bottom: 10mm;

            }

            table {
            border-collapse: collapse;
            border: 0;
            }

            div {
                font-size:small;
            }

            th, td {
            border: 1px solid #ddd; /* 테두리 색상과 두께 설정 */
            padding: 8px; /* 셀 안쪽 여백 설정 */
            font-size:small;
            }

            .footer {
                margin-top: 10mm;
            }
        } 

        @media screen {
            .packing-slip {
                max-width: 210mm;
                margin: 0 auto;
                padding: 20px;
                border: 1px solid #ddd;
            }

            table {
            border-collapse: collapse;
            border: 0;
            }

            th, td {
            border: 1px solid #ddd; /* 테두리 색상과 두께 설정 */
            padding: 8px; /* 셀 안쪽 여백 설정 */
            }
        }
    </style>
</head>

<body>
    <div class="packing-slip">
        <div class="header" style="margin-bottom:60px;">
            <h1>PACKING SLIP</h1>
        </div>

        <div class="ship-to" style="margin-bottom:40px;">

            Address : ${info.BasicInfo.Address} <br />
            Phone :${info.BasicInfo.Phone} <br />
            Email : ${info.BasicInfo.Email}
        </div>
        <hr/>

        <div class="info">

            <div class="ship-to" style="margin-bottom:60px; margin-top:30px;">
            <p>Invoice#: ${orderData.pickup_order_no}</p>
                <table width='100%'>
                    <tbody id="itemsTable">
                        <tr style="border:1px solid black">
                            <td rowspan="4" width="70px">
                                <h3>Bill To:</h3>
                            </td>
                            <td>Company</td>
                            <td>${orderData.wholesaler}</td>
                       
                            <td align='right'>Invoice Date : ${orderData.created_at.slice(
                              0,
                              10
                            )}</td>
                        </tr>
                        <tr>
                            <td>Receiver</td>
                            <td>${company.manager_name}</td>
                            <td align='right'></td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>${company.manager_tel}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>${
                              company.company_address !== null
                                ? company.company_address
                                : ""
                            }</td>
                            <td align='right'>담당자 : 우현경</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="ship-to"  style="margin-bottom:80px;">
                <table width='100%' style="background-color:whitesmokie;">
                    <thead>
                        <tr style="background-color: #cfe2ff;">
                            <th width="70px">NO</th>
                            <th>ITEM</th>
                            <th width="70px">QTY</th>
                        </tr>
                    </thead>
                    <tbody id="itemsTable">
                        ${orderData.order_product
                          .map(
                            (item, idx) => `
                        <tr>
                            <td align="center" width="70px">${idx + 1}</td>
                            <td>${item.productName}</td>
                            <td align="center" width="70px">${
                              item.qauntity
                            }</td>
                        </tr>
                        `
                          )
                          .join("")}
                        <tr>
                          
                            <td align="center" colspan='2'>총합계</td>
                            <td align="center">${amountQTY}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div style="text-align:center;margin-bottom:60px;">
        <p style="font-size: x-small;">
        <strong>${info.Policy.Return.Title}</strong><br/>
        <span>${info.Policy.Return.SubTitle}</span><br/>
        <span>${info.Policy.Return["1"]}</span><br/>
        <span>${info.Policy.Return["2"]}</span><br/>
        <span>${info.Policy.Return.CONT1}</span><br/>
        <strong>${info.Policy.OTHER.Title}</strong><br/>
        <span>${info.Policy.OTHER["1"]}</span><br/>
        <span>${info.Policy.OTHER["2"]}</span><br/>
    
        </p>
        </div>

    </div>
</body>
</html>
    `);
  printWindow.document.close();
};
