import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import {
  Table,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../../utils/AboutNumber";
const cookies = new Cookies();

function RequestDepositConfirmed({ isActive }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    // 선불충전금 요약 정보 가져오기
    if (isActive) {
      getConfirmedDepositList();
    }
  }, [isActive]);

  const getConfirmedDepositList = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/deposit/confirmed`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      console.log(resList.data);
      setList(resList.data);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <div>
      <Table hover bordered striped>
        <thead>
          <tr className="table-primary">
            <th className="text-center p-2 border">No</th>
            <th className="text-center p-2 border">등록일</th>
            <th className="text-center p-2 border">확인일</th>
            <th className="text-center p-2 border">업체명</th>
            <th className="text-center p-2 border">금액</th>
            <th className="text-center p-2 border">적립 포인트</th>
            <th className="text-center p-2 border">입금자명</th>
            <th className="text-center p-2 border">메모</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length > 0 ? (
            list.map((l, idx) => {
              return (
                <tr key={idx}>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">
                    <Moment format="YYYY-MM-DD">{l.created_at}</Moment>
                  </td>
                  <td className="text-center">
                    <Moment format="YYYY-MM-DD">{l.updated_at}</Moment>
                  </td>

                  <td className="text-center">{l.wholesaler}</td>
                  <td className="text-center">
                    ${addCommasToNumber(l.amount)}
                  </td>
                  <td className="text-center">{l.bonus_point}</td>

                  <td className="text-center">{l.depositor}</td>
                  <td className="text-center">{l.deposit_memo}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                확인대상 목록 없음
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default RequestDepositConfirmed;
