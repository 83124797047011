import React, { useState } from "react";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

function TopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const toggleSubMenu = (id) => {
    setOpenSubMenus((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const menuItems = [
    {
      id: "pay",
      title: "페이",
      link: "#",
      subItems: [
        { id: 11, title: "선불충전금", link: "/pay/deposit" },
        { id: 12, title: "포인트", link: "/pay/point" },
        { id: 13, title: "인보이스", link: "/pay/invoice" },
      ],
    },
    {
      id: "order",
      title: "주문",
      link: "#",
      subItems: [
        { id: 21, title: "엑셀주문", link: "/upload" },
        { id: 22, title: "엑셀주문목록", link: "/list" },
        { id: 23, title: "픽업주문", link: "/pickup" },
        { id: 24, title: "픽업주문목록", link: "/pickuplist" },
      ],
    },
    {
      id: "order_manage",
      title: "주문관리",
      link: "#",
      subItems: [
        { id: 31, title: "주문등록 완료", link: "/manage/registered" },
        { id: 32, title: "주문 처리 중", link: "/manage/processing" },
        { id: 33, title: "발송완료", link: "/manage/done" },
        { id: 34, title: "주문취소", link: "/manage/cancel" },
      ],
    },
    {
      id: "product",
      title: "제품",
      link: "#",
      subItems: [{ id: 21, title: "제품검색", link: "/product" }],
    },
  ];

  const renderMenuItem = (item) => {
    if (item.subItems) {
      return (
        <div key={item.id}>
          <NavItem
            className="border p-2"
            onClick={() => toggleSubMenu(item.id)}
            style={{ cursor: "pointer" }}
          >
            <NavLink>{item.title}</NavLink>
          </NavItem>
          <Collapse isOpen={openSubMenus[item.id]}>
            <Nav className="p-2" navbar>
              {item.subItems.map((subItem) => renderMenuItem(subItem))}
            </Nav>
          </Collapse>
        </div>
      );
    }
    return (
      <NavItem key={item.id}>
        <NavLink href={item.link}>- {item.title}</NavLink>
      </NavItem>
    );
  };

  return (
    <div>
      {/* 모바일 메뉴 (상단 햄버거) */}
      <Navbar color="light" light expand="md" className="d-md-none">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {menuItems.map((item) => renderMenuItem(item))}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default TopMenu;
